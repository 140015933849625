import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { tenantGroupFetch } from "../../slices/group&permissionSlice";
import LayOut from "../layout/LayOut";

const GroupsAndPermissions = () => {
  const dispatch = useDispatch();
  const { groups } = useSelector((state) => state.groups);

  useEffect(() => {
    dispatch(tenantGroupFetch());
  }, [dispatch]);

  return (
    <LayOut>
      <div className="bg-white p-5 m-7 border border-collapse rounded-t-2xl md:m-7 mt-28">
        <div className="grid grid-cols-12 gap-4">
          {groups.results?.map((group) => (
            <div className="md:col-span-4 col-span-12" key={group.id}>
              <div className="border p-3 rounded">
                <h1 className="border-b-1 text-xl uppercase text-gray-600 m-0">
                  {group.name}
                </h1>
                <div className="allTenants border-b-1 py-4">
                  <p className="text-lg">Tenants: 10</p>
                </div>
                <div className="pt-3">
                  <button
                    style={{ background: "#5d337f" }}
                    className="block text-white py-1 px-5 m-auto text-center rounded-md font-semibold"
                  >
                    <Link
                      to={`/groupsAndPermissions/${group.id}`}
                      className="text-white hover:text-white"
                    >
                      Read More
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </LayOut>
  );
};

export default GroupsAndPermissions;
