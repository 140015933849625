// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".staff_wrapper__Ehvat {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.5);\n    z-index: 1000;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  .staff_container__ohGmt {\n    /* background-color: #314059; */\n    background-color: #fafafa;\n    max-width: 650px;\n    /* width: 90%; */\n    width: 45%;\n    margin: 0 auto;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 2.8rem 2.2rem;\n    border-radius: 5px;\n    position: relative;\n  }\n  ", "",{"version":3,"sources":["webpack://./src/pages/staffManagement/staff.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;EACA;IACE,+BAA+B;IAC/B,yBAAyB;IACzB,gBAAgB;IAChB,gBAAgB;IAChB,UAAU;IACV,cAAc;IACd,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;IAClB,kBAAkB;EACpB","sourcesContent":[".wrapper {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.5);\n    z-index: 1000;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  .container {\n    /* background-color: #314059; */\n    background-color: #fafafa;\n    max-width: 650px;\n    /* width: 90%; */\n    width: 45%;\n    margin: 0 auto;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 2.8rem 2.2rem;\n    border-radius: 5px;\n    position: relative;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "staff_wrapper__Ehvat",
	"container": "staff_container__ohGmt"
};
export default ___CSS_LOADER_EXPORT___;
