import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { message } from "antd";
import baseUrl from "../redux/baseUrl";

export const loginAuth = createAsyncThunk(
  "login",
  async (data = null, { rejectWithValue }) => {
    try {
      const response = await baseUrl.post(
        `api/v1/users/api/token/?tenantId=8`,
        data.user
      );
      localStorage.setItem(
        "login",
        JSON.stringify({
          login: true,
          token: response.data.access,
          refreshToken: response.data.refresh,
          user: response.data.user,
        })
      );
      // data.history.push("/");
      window.location.pathname = "/";
      return response.data;
    } catch ({ response }) {
      if (response.status === 401) {
        // window.location.href = "/401";
      }
      // message.error(response.data.detail);
      return rejectWithValue(response.data.detail);
    }
  }
);

export const checkToken = createAsyncThunk(
  "login/checkToken",
  async (token, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await baseUrl.post(`/api/v1/users/check-token/`, token);

      if (response.status !== 200) {
        localStorage.removeItem("login");
      }

      return response.data;
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      return rejectWithValue(response.data);
    }
  }
);

const loginSlice = createSlice({
  name: "login",
  initialState: {
    user: localStorage.getItem("login")
      ? JSON.parse(localStorage.getItem("login"))
      : null,
    loading: false,
    error: null,
    success: false,
    isCkeckedToken: null,
  },
  reducers: {
    logout: (state) => {
      state.user = null;
      localStorage.removeItem("login");
      window.location.href = "/login";
    },
  },
  extraReducers: {
    [loginAuth.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    },
    [loginAuth.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.user = action.payload;
    },

    [loginAuth.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    [checkToken.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    },
    [checkToken.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.isCkeckedToken = action.payload;
    },

    [checkToken.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const { logout } = loginSlice.actions;
export default loginSlice.reducer;
