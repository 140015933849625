import axios from "axios";

const user = localStorage.getItem("login")
  ? JSON.parse(localStorage.getItem("login"))
  : null;

export const url = "https://py.e-mart.app";

export const authApiUrl = axios.create({
  baseURL: "https://py.e-mart.app",
  headers: {
    Authorization: `Bearer ${user?.token}`,
  },
  params: {
    tenantId: user?.user?.tenant,
  },
});

export const authApiFormData = axios.create({
  baseURL: "https://py.e-mart.app",
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${user?.token}`,
  },
});

export const authApi = axios.create({
  baseURL: "https://py.e-mart.app",
  headers: {
    Authorization: `Bearer ${user?.token}`,
  },
});

export default axios.create({
  baseURL: "https://py.e-mart.app",
});
