import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import baseUrl, {
  authApi,
  authApiFormData,
  authApiUrl,
} from "../redux/baseUrl";

export const getAlltenantManagement = createAsyncThunk(
  "tenant/getAlltenantManagement",
  async ({ page, pageSize, searchTenant }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await authApi.get(
        `/api/v1/tenants/?page=${page}&page_size=${pageSize}&${
          searchTenant ? `name__icontains=${searchTenant}` : ""
        }`
      );

      // for (let tenant of response.data.results) {
      // call get endpoint by tenant.id
      // tenant["first_name"] = result_new["first_name"]
      // tenant["last_name"] = result_new["last_name"]
      // }

      // for (let i = 0; i < response?.data?.results.length; i++) {
      //   let TenantID = response.data.results[i].id;
      //   const res = await baseUrl.get(
      //     `api/v1/users/?tenantId=${TenantID}&main=True`
      //   );
      //   if (res.data.count >= 1) {
      //     response.data.results[i]["first_Name"] =
      //       res.data.results[0]["firstName"];
      //     response.data.results[i]["last_Name"] =
      //       res.data.results[0]["lastName"];
      //     response.data.results[i]["is_active"] =
      //       res.data.results[0]["is_active"];
      //     response.data.results[i]["user_id"] = res.data.results[0]["id"];
      //   }
      // }

      return response.data;
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      return rejectWithValue(response.data);
    }
  }
);

export const deleteTenantManagement = createAsyncThunk(
  "tenant/deleteTenantManagement",
  async (id = null, { rejectWithValue }) => {
    try {
      const response = await authApiUrl.delete(
        `/api/v1/tenants/soft-delete/${id}`
      );

      return id;
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      return rejectWithValue(response?.data);
    }
  }
);

export const editTenant = createAsyncThunk(
  "tenant/editTenant",
  async ({ content, id }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await authApiFormData.put(
        `/api/v1/tenants/update/${id}/`,
        content
      );

      return res.data;
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      return rejectWithValue(response.data);
    }
  }
);

export const getTenantManagement = createAsyncThunk(
  "tenant/getTenantManagement",
  async (id = null, { rejectWithValue }) => {
    try {
      const response = await authApiUrl.get(`/api/v1/tenants/${id}/`);

      const res = await baseUrl.get(`api/v1/users/?tenantId=${id}&main=True`);
      if (res.data.count >= 1) {
        response.data["first_Name"] = res.data.results[0]["firstName"];
        response.data["last_Name"] = res.data.results[0]["lastName"];
        response.data["is_active"] = res.data.results[0]["is_active"];
        response.data["user_id"] = res.data.results[0]["id"];
      }

      return response.data;
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      return rejectWithValue(response?.data);
    }
  }
);

const initialState = {
  allTenant: [],
  specificTenant: {},
  isLoading: null,
  error: null,
};

const tenantSlice = createSlice({
  name: "tenant",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch categories:
    builder
      .addCase(getAlltenantManagement.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAlltenantManagement.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allTenant = action.payload;
      })
      .addCase(getAlltenantManagement.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(getTenantManagement.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getTenantManagement.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.specificTenant = action.payload;
      })
      .addCase(getTenantManagement.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(deleteTenantManagement.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteTenantManagement.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allTenant.results = state.allTenant.results.filter(
          (tenant) => tenant.id !== action.payload
        );
      })
      .addCase(deleteTenantManagement.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default tenantSlice.reducer;
