import { Button } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { FiEdit } from "react-icons/fi";
import { MdDone, MdOutlineKeyboardBackspace } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  createFeature,
  createPlan,
  editSpecificPlan,
  getAllFeatures,
  getSpecificPlan,
} from "../../slices/subscriptionSlice";
import LayOut from "../layout/LayOut";
import toast from "react-hot-toast";
import { GrAddCircle } from "react-icons/gr";
import { IoIosAddCircleOutline } from "react-icons/io";

const NewSubscriptionPlan = () => {
  /* Use State */
  const [editText, setEditText] = useState(false);
  const [name, setName] = useState("Plan Name");
  const [orders, setOrders] = useState("");
  const [storage, setStorage] = useState("");
  const [duration, setDuration] = useState("");
  const [products, setProducts] = useState("");
  const [value, setValue] = useState("");
  const [photos, setPhotos] = useState("");
  const [valueSetup, setValueSetup] = useState("");
  const [videos, setVideos] = useState("");
  const [valueCustomization, setValueCustomization] = useState("");
  const [categories, setCategories] = useState("");
  const [currency, setCurrency] = useState("");
  const [oldPrice, setOldPrice] = useState("");

  const [popular, setPopular] = useState(undefined);
  const [specificPlanIDs, setSpecificPlanID] = useState([]);
  const [feature, setFeature] = useState([]);

  const dispatch = useDispatch();
  const { isLoading, allFeatures, error } = useSelector((state) => state.plans);

  const mynavigate = useNavigate();

  /* Use Effect */
  useEffect(() => {
    dispatch(getAllFeatures());
  }, [dispatch]);

  const loadingElement = (
    <div className="border border-blue-300 shadow rounded-md p-4 max-w-sm w-full mx-auto">
      <div className="animate-pulse flex space-x-4">
        <div className="rounded-full bg-slate-200 h-10 w-10"></div>
        <div className="flex-1 space-y-6 py-1">
          <div className="h-2 bg-slate-200 rounded"></div>
          <div className="space-y-3">
            <div className="grid grid-cols-3 gap-4">
              <div className="h-2 bg-slate-200 rounded col-span-2"></div>
              <div className="h-2 bg-slate-200 rounded col-span-1"></div>
            </div>
            <div className="h-2 bg-slate-200 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  );

  const createPlanHandler = async (e) => {
    e.preventDefault();

    const planData = {
      name,
      Orders: orders,
      storage,
      duration,
      Products: products,
      value,
      Photos: photos,
      value_setup: valueSetup,
      Videos: videos,
      value_customization: valueCustomization,
      Categories: categories,
      old_price: oldPrice,
      popular,
      feature: specificPlanIDs,
      main:true,
    };

    let validNumber =
      storage >= 0 &&
      duration >= 0 &&
      products >= 0 &&
      value >= 0 &&
      photos >= 0 &&
      valueSetup >= 0 &&
      videos >= 0 &&
      valueCustomization >= 0 &&
      categories >= 0;

    let res = "";

    if (validNumber) {
      res = await dispatch(createPlan(planData));

      // if (
      //   res.payload.planCode[0] === "plan with this planCode already exists."
      // ) {
      //   toast.success(res.payload.planCode[0]);
      // }

      if (res.meta.requestStatus === "fulfilled") {
        toast.success("Plan is created");
        setTimeout(() => {
          mynavigate("/subscriptionPlans");
        }, 1500);
      }
    } else {
      toast.error("values must be >= 0");
    }
  };

  const addFeature = (e, id) => {
    if (specificPlanIDs.includes(id)) {
      let perList = specificPlanIDs.filter(function (item) {
        return item !== id;
      });
      setSpecificPlanID(perList);
    } else {
      setSpecificPlanID([...specificPlanIDs, id]);
    }
  };

  const createfeatureHandler = async () => {
    let res = await dispatch(createFeature({ name: feature }));

    if (res.meta.requestStatus === "fulfilled") {
      toast.success("Feature is created");
      setFeature("");
      setTimeout(() => {
        mynavigate("/subscriptionPlans");
      }, 1500);
    }

    if (res.meta.requestStatus === "rejected") {
      toast.error("Something Went Wrong !");
    }
  };

  const isValid =
    categories &&
    photos &&
    products &&
    videos &&
    storage &&
    duration &&
    value &&
    specificPlanIDs.length > 0;

  return (
    <LayOut>
      {/* Return To Subscription Plans */}
      <Link style={{ color: "#5d337f" }} to="/subscriptionPlans">
        <div className="flex m-7 mt-4 mb-0 items-center cursor-pointer">
          <MdOutlineKeyboardBackspace className="mr-1 ml-2 text-xl" />
          <span className="font-semibold uppercase">Return</span>
        </div>
      </Link>
      {/* Content */}
      <div className="bg-white p-5 m-7 mt-4 border border-collapse rounded-t-2xl">
        {isLoading ? (
          loadingElement
        ) : (
          <>
            <div className="p-2 rounded flex" style={{ background: "#5d337f" }}>
              <h1 className="m-0 text-white text-center text-lg flex mx-auto items-center">
                {editText && (
                  <>
                    <input
                      type="text"
                      className="text-white  bg-transparent border-b-2 outline-none"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                    <MdDone
                      className="ml-2 cursor-pointer"
                      onClick={() => setEditText(false)}
                      style={{ opacity: `${!name ? "0" : "1"}` }}
                    />
                  </>
                )}
                {!editText && (
                  <>
                    {name}
                    <FiEdit
                      className="ml-2 cursor-pointer"
                      onClick={() => setEditText(true)}
                    />
                  </>
                )}
              </h1>
            </div>
            {/* Plan Details */}
            <div>
              {/* max-w-lg */}
              <form className="w-full mt-5 px-5">
                
                {/* STORAGE & DURATION */}
                <div className="w-full flex flex-wrap justify-evenly">
                  <div className="md:flex md:items-center  md:w-1/3">
                    <div className="md:w-1/3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        storage
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                          storage && "border-gray-500"
                        } ${
                          !storage && "border-red-500"
                        } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                        id="inline-full-name"
                        type="number"
                        value={storage}
                        onChange={(e) => setStorage(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center md:w-1/3">
                    <div className="md:w-1/3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        duration
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                          duration && "border-gray-500"
                        } ${
                          !duration && "border-red-500"
                        } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                        id="inline-full-name"
                        type="number"
                        value={duration}
                        onChange={(e) => setDuration(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                {/* PRODUCTS & VALUE */}
                <div className="w-full flex flex-wrap justify-evenly">
                  <div className="md:flex md:items-center  md:w-1/3">
                    <div className="md:w-1/3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Products
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                          products && "border-gray-500"
                        } ${
                          !products && "border-red-500"
                        } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                        id="inline-full-name"
                        type="number"
                        value={products}
                        onChange={(e) => setProducts(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center md:w-1/3">
                    <div className="md:w-1/3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        value
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                          value && "border-gray-500"
                        } ${
                          !value && "border-red-500"
                        } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                        id="inline-full-name"
                        type="number"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                {/* PHOTOS & VALUE SETUP */}
                <div className="w-full flex flex-wrap justify-evenly">
                  <div className="md:flex md:items-center  md:w-1/3">
                    <div className="md:w-1/3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        photos
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                          photos && "border-gray-500"
                        } ${
                          !photos && "border-red-500"
                        } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                        id="inline-full-name"
                        type="number"
                        value={photos}
                        onChange={(e) => setPhotos(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center md:w-1/3">
                    <div className="md:w-1/3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        value Setup
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                          valueSetup && "border-gray-500"
                        } ${
                          !valueSetup && "border-red-500"
                        } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                        id="inline-full-name"
                        type="number"
                        value={valueSetup}
                        onChange={(e) => setValueSetup(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                {/* VIDEOS & VALUE SETUP */}
                <div className="w-full flex flex-wrap justify-evenly">
                  <div className="md:flex md:items-center  md:w-1/3">
                    <div className="md:w-1/3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Videos
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                          videos && "border-gray-500"
                        } ${
                          !videos && "border-red-500"
                        } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                        id="inline-full-name"
                        type="number"
                        value={videos}
                        onChange={(e) => setVideos(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center md:w-1/3">
                    <div className="md:w-1/3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        value Customization
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                          valueCustomization && "border-gray-500"
                        } ${
                          !valueCustomization && "border-red-500"
                        } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                        id="inline-full-name"
                        type="number"
                        value={valueCustomization}
                        onChange={(e) => setValueCustomization(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                {/* CATEGORIES & CURRENCY */}
                <div className="w-full flex flex-wrap justify-evenly">
                  <div className="md:flex md:items-center  md:w-1/3">
                    <div className="md:w-1/3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Categories
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                          categories && "border-gray-500"
                        } ${
                          !categories && "border-red-500"
                        } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                        id="inline-full-name"
                        type="number"
                        value={categories}
                        onChange={(e) => setCategories(e.target.value)}
                      />
                      {categories < 0 && (
                        <p className="text-red-500 text-xs italic">
                          this field must be greater thant zero
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="md:flex md:items-center md:w-1/3">
                    <div className="md:w-1/3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Old Price
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                          oldPrice && "border-gray-500"
                        } ${
                          !oldPrice && "border-red-500"
                        } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                        id="inline-full-name"
                        type="text"
                        value={oldPrice}
                        onChange={(e) => setOldPrice(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                {/* PLAN CODE & ORDERS */}
                <div className="w-full flex flex-wrap justify-evenly">
                <div className="md:flex md:items-center  md:w-1/3">
                    <div className="md:w-1/3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Orders
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                          orders && "border-gray-500"
                        } ${
                          !orders && "border-red-500"
                        } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                        id="inline-full-name"
                        type="text"
                        value={orders}
                        onChange={(e) => setOrders(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center  md:w-1/3"></div>
                  {/* <div className="md:flex md:items-center  md:w-1/3">

                    <div className="md:w-1/3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Plan Code
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                          planCode && "border-gray-500"
                        } ${
                          !planCode && "border-red-500"
                        } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                        id="inline-full-name"
                        type="text"
                        value={planCode}
                        onChange={(e) => setPlanCode(e.target.value)}
                      />
                    </div>
                  </div> */}
                </div>
                <div className="flex justify-evenly">
                  <div
                    className="flex items-center"
                    style={{ width: "78%", margin: "auto" }}
                  >
                    <input
                      className=""
                      type="checkbox"
                      checked={popular}
                      value={popular}
                      onChange={() => setPopular(!popular)}
                    />
                    <span
                      className="text-lg font-normal ml-2"
                      style={{ marginTop: "-5px" }}
                    >
                      popular
                    </span>
                  </div>
                </div>
                <div className="border-b mt-10"></div>
              </form>
            </div>
            {/* Features */}
            <div className="feature p-5">
              <h1
                className="text-xl font-bold mb-6"
                style={{ color: "#5d337f", letterSpacing: ".2px" }}
              >
                Features
              </h1>
              <div>
                <div className="grid md:grid-cols-12 gap-4">
                  {allFeatures?.results?.map((feature) => (
                    <div className="col-span-4" key={feature.id}>
                      <div className="flex items-center">
                        <input
                          className=""
                          type="checkbox"
                          checked={specificPlanIDs?.includes(feature?.id)}
                          onChange={(e) => addFeature(e, feature?.id)}
                        />
                        <span
                          className="text-lg font-normal ml-2"
                          style={{ marginTop: "-4px" }}
                        >
                          {feature.name}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="create_Feature mt-5">
                  <div className="w-full flex flex-wrap">
                    <div className="md:flex md:items-center md:w-1/3">
                      <input
                        className={`mr-2 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white`}
                        id="inline-full-name"
                        type="text"
                        value={feature}
                        onChange={(e) => setFeature(e.target.value)}
                      />
                      <IoIosAddCircleOutline
                        style={{
                          fontSize: "30px",
                          cursor: "pointer",
                          color: "#0dc159",
                        }}
                        onClick={createfeatureHandler}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-end mt-5">
                  <Button
                    style={{
                      background: "rgb(254 240 138)",
                      color: "#5d337f",
                      borderRadius: "5px",
                      fontWeight: "bold",
                      Padding: "5px 20px",
                      opacity: `${isValid ? "1" : "0.5"}`,
                    }}
                    onClick={createPlanHandler}
                    disabled={!isValid}
                  >
                    Create
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </LayOut>
  );
};

export default NewSubscriptionPlan;
