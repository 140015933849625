import React from "react";
import { Link } from "react-router-dom";
import ErrorIMG from "../data/Group 1690.png";

const ErrorPage = () => {
  return (
    <div className="bg-gray-100 h-screen">
      <div className="bg-purple-900 h-16 w-full"></div>
      <div className="w-3/4 mx-auto">
        <img src={ErrorIMG} alt="" />
        <Link to='/' className="goBack2">Go Back</Link>
      </div>
      <div className="bg-purple-900 w-full text-center text-white font-bold tracking-wide	py-0 text-xl pb-1 p-4 mt-6 fixed bottom-0">
        Copyright &copy;{" "}
        <a
          href="https://teqneia.com/"
          className="text-yellow-300"
          target={`_blank`}
        >
          TEQNEIA
        </a>{" "}
        2015 - 2022
      </div>
    </div>
  );
};

export default ErrorPage;
