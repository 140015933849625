import { Button, Pagination } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  activeNewRequest,
  getAllNewTenant,
  TenantIDAction,
} from "../../slices/newRequestSlice";
import LayOut from "../layout/LayOut";

const NewRequests = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(3);
  const [searchTenant, setTenantSearch] = useState("");

  const navigate = useNavigate();

  const { requestsTenant, reSetTenantID } = useSelector(
    (state) => state.newRequest
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllNewTenant({ page: 1, pageSize: 3, searchTenant: "" }));
  }, []);

  const onPageNumberChange = async (current) => {
    setPage(current);
    const res = await dispatch(
      getAllNewTenant({ page: current, pageSize, searchTenant })
    );
    if (res.meta.requestStatus === "fulfilled") {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  const onSearchTenantChange = (e) => {
    setPage(1);
    dispatch(
      getAllNewTenant({
        page: 1,
        pageSize,
        searchTenant: searchTenant,
      })
    );
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      onSearchTenantChange();
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [searchTenant]);

  const activeFtpHandler = async (tenantUserName, tenantID, userID) => {
    if (tenantUserName) {
      dispatch(activeNewRequest({ id: userID }));
    }

    if (!tenantUserName) {
      navigate("/FTBManagement");
      dispatch(TenantIDAction.toggle(tenantID));
    }
  };

  return (
    <LayOut>
      <div className="bg-white p-5 m-7 border border-collapse rounded-t-2xl md:m-7 mt-28">
        <form>
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only"
          >
            Search
          </label>
        </form>
        <div className="relative">
          <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <svg
              aria-hidden="true"
              className="w-5 h-5 text-gray-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </div>
          <input
            onChange={(e) => setTenantSearch(e.target.value)}
            type="search"
            id="default-search"
            className="outline-0 block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Search..."
            required=""
          />
          <button
            type="submit"
            className="text-white absolute right-2.5 bottom-2.5 bg-purple-800 hover:bg-purple-900 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
          >
            Search
          </button>
        </div>
        {requestsTenant?.results?.map((tenant) => (
          <div key={tenant.id}>
            <div className="grid md:grid-cols-10 mt-4">
              <div className="col-span-2">
                <span className="text-center block font-semibold text-lg text-gray-600">
                  Tenant Name
                </span>
              </div>
              <div className="col-span-2">
                <span className="text-center block font-semibold text-lg text-gray-600">
                  Tenant ID
                </span>
              </div>
              <div className="col-span-2">
                <span className="text-center block font-semibold text-lg text-gray-600">
                  User ID
                </span>
              </div>
              <div className="col-span-2">
                <span className="text-center block font-semibold text-lg text-gray-600">
                  Host
                </span>
              </div>
              <div className="col-span-2">
                <span className="text-center block font-semibold text-lg text-gray-600">
                  FTP
                </span>
              </div>
            </div>
            <div className="bg-slate-50 rounded-l-md border-1 text-black text-base mt-2">
              <div className="grid md:grid-cols-10">
                <div className="col-span-2 p-2">
                  <span className="text-center block font-semibold text-lg text-gray-600">
                    {tenant.name}
                  </span>
                </div>
                <div className="col-span-2 p-2">
                  <span className="text-center block font-semibold text-lg text-gray-600">
                    {tenant.id}
                  </span>
                </div>
                <div className="col-span-2 p-2">
                  <span className="text-center block font-semibold text-lg text-gray-600">
                    {tenant["user_id"] ? tenant["user_id"] : "________"}
                  </span>
                </div>
                <div className="col-span-2 p-2">
                  <span className="text-center block font-semibold text-lg text-gray-600">
                    {tenant.host}
                  </span>
                </div>
                <div className="col-span-2 p-2">
                  <span className="text-center block font-semibold text-lg text-gray-600">
                    <input
                      className=""
                      type="checkbox"
                      checked={tenant.usernameFTP}
                      // defaultChecked={tenant.usernameFTP}
                      // value={popular}
                      // onChange={() => setPopular(!popular)}
                      // disabled={tenant.usernameFTP}
                    />
                  </span>
                </div>
              </div>
              {/* FTB Content */}
              <div className="mt-7 grid md:grid-cols-9 gap-4 col-span-3 border-1 bg-slate-100 rounded-l-md text-black text-base p-2 m-2">
                <div className="col-span-3">
                  <div className="flex justify-between bg-white p-2 items-center rounded">
                    <h3 className="m-0">Host</h3>
                    <span style={{ color: "#5d337f", fontWeight: "600" }}>
                      {tenant["FTP_host"] ? tenant["FTP_host"] : "______"}
                    </span>
                  </div>
                </div>
                <div className="col-span-3">
                  <div className="flex justify-between bg-white p-2 items-center rounded">
                    <h3 className="m-0">User Name</h3>
                    <span style={{ color: "#5d337f", fontWeight: "600" }}>
                      {tenant["usernameFTP"] ? tenant["usernameFTP"] : "______"}
                    </span>
                  </div>
                </div>
                <div className="col-span-3">
                  <div className="flex justify-between bg-white p-2 items-center rounded">
                    <h3 className="m-0">Password</h3>
                    <span style={{ color: "#5d337f", fontWeight: "600" }}>
                      {tenant["passwordFTP"] ? tenant["passwordFTP"] : "______"}
                    </span>
                  </div>
                </div>
                <div className="col-span-3">
                  <div className="flex justify-between bg-white p-2 items-center rounded">
                    <h3 className="m-0">Path Upload</h3>
                    <span style={{ color: "#5d337f", fontWeight: "600" }}>
                      {tenant["path_uploadFTP"]
                        ? tenant["path_uploadFTP"]
                        : "______"}
                    </span>
                  </div>
                </div>
                <div className="col-span-3">
                  <div className="flex justify-between bg-white p-2 items-center rounded">
                    <h3 className="m-0">Path Returned</h3>
                    <span style={{ color: "#5d337f", fontWeight: "600" }}>
                      {tenant["path_returnedFTP"]
                        ? tenant["path_returnedFTP"]
                        : "______"}
                    </span>
                  </div>
                </div>
                <div className="col-span-3">
                  <div className="flex justify-end">
                    <Button
                      style={{
                        background: `${
                          tenant.usernameFTP ? "#16b565" : "rgb(254, 240, 138)"
                        }`,
                        color: `${
                          tenant.usernameFTP ? "#fff" : "rgb(93, 51, 127)"
                        }`,
                        borderRadius: "5px",
                        fontWeight: "600",
                        padding: "0px 25px",
                      }}
                      onClick={() =>
                        activeFtpHandler(
                          tenant.usernameFTP,
                          tenant.id,
                          tenant.user_id
                        )
                      }
                      // onClick={()=> dispatch(activeNewRequest({id: tenant.user_id}))}
                    >
                      {tenant.usernameFTP ? "Active" : "Add"}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="border-b-2 my-8"></div>
          </div>
        ))}
        <div className="mt-2 flex justify-between p-2">
          <Pagination
            defaultCurrent={page}
            current={page}
            total={requestsTenant.count}
            onChange={onPageNumberChange}
            pageSize={pageSize}
          />
        </div>
      </div>
    </LayOut>
  );
};

export default NewRequests;
