import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import baseUrl, {
  authApi,
  authApiFormData,
  authApiUrl,
} from "../redux/baseUrl";
import axios from "axios";

const user = localStorage.getItem("login")
  ? JSON.parse(localStorage.getItem("login"))
  : null;

export const getAllStaff = createAsyncThunk(
  "staff/getAllStaff",
  async ({ page, pageSize, searchTenant }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await authApiUrl.get(
        `/api/v1/users/staffs/?page=${page}&page_size=${pageSize}&${
          searchTenant ? `user__userName__icontains=${searchTenant}` : ""
        }`
      );
      return response.data;
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      return rejectWithValue(response.data);
    }
  }
);

export const createUser = createAsyncThunk(
  "staff/createUser",
  async (userData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await authApiUrl.post(`/api/v1/users/create/`, userData);

      return response.data;
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      return rejectWithValue(response?.data);
    }
  }
);

export const createStaff = createAsyncThunk(
  "staff/createStaff",
  async (staffData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await authApiUrl.post(
        `/api/v1/users/staffs/create/`,
        staffData
      );

      return response.data;
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      return rejectWithValue(response?.data);
    }
  }
);

export const deleteStaffManagement = createAsyncThunk(
  "staff/deleteStaffManagement",
  async (id = null, { rejectWithValue }) => {
    try {
      let response = await fetch(
        `https://py.e-mart.app/api/v1/tenants/soft-delete/${id}/?tenantId=${user?.user?.tenant}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      if (response.status === 403) {
        window.location.href = "/403";
      } else {
        return id;
      }
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      return rejectWithValue(response?.data);
    }
  }
);

export const deleteStaffWithUser = createAsyncThunk(
  "staff/deleteStaffWithUser",
  async (id = null, { rejectWithValue }) => {
    try {
      let response = await fetch(
        `https://py.e-mart.app/api/v1/users/soft-delete/${id}/?tenantId=8&delete=all`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      if (response.status === 403) {
        window.location.href = "/403";
      } else {
        return id;
      }
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      return rejectWithValue(response?.data);
    }
  }
);

export const editStaff = createAsyncThunk(
  "staff/editStaff",
  async ({ data, id }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.put(
        `https://py.e-mart.app/api/v1/users/staffs/update/${id}/?tenantId=8`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      return res.data;
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      return rejectWithValue(response.data);
    }
  }
);

export const editUser = createAsyncThunk(
  "staff/editUser",
  async ({ data, id }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.put(
        `https://py.e-mart.app/api/v1/users/update/${id}/?tenantId=8`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      return res.data;
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      return rejectWithValue(response.data);
    }
  }
);

const initialState = {
  allStaff: [],
  user: {},
  staff: {},
};

const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch categories:
    builder
      .addCase(getAllStaff.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAllStaff.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allStaff = action.payload;
      })
      .addCase(getAllStaff.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(createUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(createStaff.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createStaff.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.staff = action.payload;
      })
      .addCase(createStaff.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(deleteStaffManagement.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteStaffManagement.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allStaff.results = state.allStaff.results.filter(
          (tenant) => tenant.id !== action.payload
        );
      })
      .addCase(deleteStaffManagement.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default staffSlice.reducer;
