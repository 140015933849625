import React from "react";
import LayOut from "../layout/LayOut";
import { useSelector } from "react-redux";
import { useState } from "react";
import Chart from "chart.js/auto";

import { LineChart, Pie } from "../../components";
import BarChart from "../../components/Charts/BarChart";

const Dashboard = () => {
  const [year, setYear] = useState(new Date().getFullYear());
  const { allTenants } = useSelector((state) => state.dashboard);

  return (
    <LayOut>
      <div className="bg-white p-5 m-7 border border-collapse rounded-t-2xl md:m-7 mt-24">
        <div className="grid md:grid-cols-12 gap-4">
          <div className="col-span-6 p-4 rounded border shadow-md">
            <h1 className="uppercase tracking-wide text-gray-700 text-md font-bold mb-4">
              Annual Revneue
            </h1>
            <BarChart />
          </div>
        </div>
        <div className="grid md:grid-cols-12 gap-4 mt-4">
          {/* Total Tenants */}
          <div className="col-span-6 md:col-span-8 p-3 rounded shadow-md border">
            <div className="flex justify-between items-center">
              <h1 className="uppercase tracking-wide text-gray-700 text-md font-bold">
                Total Tenants: <span>{allTenants?.count}</span>
              </h1>
              <input
                type="number"
                placeholder="Enter Year"
                className={`bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white border-gray-300`}
                onChange={(e) => setYear(e.target.value)}
                value={year}
              />
            </div>
            <LineChart setYear={setYear} year={year} />
          </div>
          {/* Total Plans */}
          <div className="col-span-6 md:col-span-4 bg-gray-50 p-4 rounded border shadow-md">
          <h1 className="uppercase tracking-wide text-gray-700 text-md font-bold mb-0">
              Subscription plans
            </h1>
            <Pie setYear={setYear} year={year} />
          </div>
        </div>
      </div>
    </LayOut>
  );
};

export default Dashboard;
