import React from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import "./App.css";
import { Orders } from "./pages";
import Dashboard from "./pages/dashboard/Dashboard";
import ErrorPage from "./pages/ErrorPage";
import Financials from "./pages/Financials";
import SystemAdministration from "./pages/SystemAdministration";
import NewRequests from "./pages/newRequestPage/NewRequests";
import Login from "./components/login/Login";
import Error401 from "./pages/Error401";
import TenantManagement from "./pages/TenantManagement/TenantManagement";
import Staff from "./pages/staffManagement/Staff";
import GroupsAndPermissions from "./pages/GroupsAndPermissions/GroupsAndPermissions";
import AddPermissions from "./pages/GroupsAndPermissions/AddPermissions";
import Visitors from "./pages/visitors/Visitors";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserProfile } from "./components";
import SubscriptionPlan from "./pages/subscriptionPlans/SubscriptionPlan";
import SpecificPlan from "./pages/subscriptionPlans/SpecificPlan";
import { Toaster } from "react-hot-toast";
import NewSubscriptionPlan from "./pages/subscriptionPlans/NewSubscriptionPlan";
import FTB_Management from "./pages/FTB Management/FTB_Management";
import { checkToken } from "./slices/loginAuth";
import ProtectedRoute from "./ProtectedRoute";
const App = () => {
  const { user } = useSelector((state) => state.auth);
  const mynavigate = useNavigate();
  const dispatch = useDispatch();
  // var userr = localStorage.getItem("login");
  // console.log({user})
  // console.log({userr})  //trying new one, but not submetted
  useEffect(() => {
    if (user) {
      return;
    } else if (!user || user === null) {
      mynavigate("/login");
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      dispatch(checkToken({ token: user?.token }));
    }
  }, []);

  return (
    <>
      <div>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/401" element={<Error401 />} />

          {/* Error Page */}
          <Route
            path="*"
            element={
              <ProtectedRoute
                component={<ErrorPage />}
                isAuthenticated={user} // replace with your authentication logic
                redirectTo={"/login"}
              />
            }
          />

          {/* DashBoard */}
          <Route
            path="/"
            element={
              <ProtectedRoute
                component={<Dashboard />}
                isAuthenticated={user} // replace with your authentication logic
                redirectTo={"/login"}
              />
            }
          />

          <Route
            path="/dashboard"
            element={
              <ProtectedRoute
                component={<Dashboard />}
                isAuthenticated={user} // replace with your authentication logic
                redirectTo={"/login"}
              />
            }
          />
          {/* Pages */}
          <Route
            path="/orders"
            element={
              <ProtectedRoute
                component={<Orders />}
                isAuthenticated={user} // replace with your authentication logic
                redirectTo={"/login"}
              />
            }
          />
          <Route
            path="/financials"
            element={
              <ProtectedRoute
                component={<Financials />}
                isAuthenticated={user} // replace with your authentication logic
                redirectTo={"/login"}
              />
            }
          />
          <Route
            path="/newRequest"
            element={
              <ProtectedRoute
                component={<NewRequests />}
                isAuthenticated={user} // replace with your authentication logic
                redirectTo={"/login"}
              />
            }
          />
          <Route
            path="/visitors"
            element={
              <ProtectedRoute
                component={<Visitors />}
                isAuthenticated={user} // replace with your authentication logic
                redirectTo={"/login"}
              />
            }
          />
          <Route
            path="/systemAdministration"
            element={
              <ProtectedRoute
                component={<SystemAdministration />}
                isAuthenticated={user} // replace with your authentication logic
                redirectTo={"/login"}
              />
            }
          />
          <Route
            path="/tenantManagement"
            element={
              <ProtectedRoute
                component={<TenantManagement />}
                isAuthenticated={user} // replace with your authentication logic
                redirectTo={"/login"}
              />
            }
          />
          <Route
            path="/staffManagement"
            element={
              <ProtectedRoute
                component={<Staff />}
                isAuthenticated={user} // replace with your authentication logic
                redirectTo={"/login"}
              />
            }
          />
          <Route
            path="/groupsAndPermissions"
            element={
              <ProtectedRoute
                component={<GroupsAndPermissions />}
                isAuthenticated={user} // replace with your authentication logic
                redirectTo={"/login"}
              />
            }
          />
          <Route
            path="/groupsAndPermissions/9"
            element={
              <ProtectedRoute
                component={<AddPermissions />}
                isAuthenticated={user} // replace with your authentication logic
                redirectTo={"/login"}
              />
            }
          />
          <Route
            path="/FTBManagement"
            element={
              <ProtectedRoute
                component={<FTB_Management />}
                isAuthenticated={user} // replace with your authentication logic
                redirectTo={"/login"}
              />
            }
          />
          <Route
            path="/userProfile"
            element={
              <ProtectedRoute
                component={<UserProfile />}
                isAuthenticated={user} // replace with your authentication logic
                redirectTo={"/login"}
              />
            }
          />
          <Route
            path="/subscriptionPlans"
            element={
              <ProtectedRoute
                component={<SubscriptionPlan />}
                isAuthenticated={user} // replace with your authentication logic
                redirectTo={"/login"}
              />
            }
          />
          <Route
            path="/subscriptionPlans/:id"
            element={
              <ProtectedRoute
                component={<SpecificPlan />}
                isAuthenticated={user} // replace with your authentication logic
                redirectTo={"/login"}
              />
            }
          />
          <Route
            path="/newPlan"
            element={
              <ProtectedRoute
                component={<NewSubscriptionPlan />}
                isAuthenticated={user} // replace with your authentication logic
                redirectTo={"/login"}
              />
            }
          />
        </Routes>
        <Toaster
          position="top-center"
          toastOptions={{
            style: {
              fontSize: "1rem",
            },
          }}
        />
      </div>
    </>
  );
};

export default App;

// {user && (
//  <>
//    {/* Error Page */}
//    <Route path="*" element={<ErrorPage />} />
//    {/* DashBoard */}
//    <Route path="/" element={<Dashboard />} />
//    <Route path="/dashboard" element={<Dashboard />} />
//    {/* Pages */}
//    <Route path="/orders" element={<Orders />} />
//    <Route path="/financials" element={<Financials />} />
//    <Route path="/newRequest" element={<NewRequests />} />
//    <Route path="/visitors" element={<Visitors />} />
//    <Route
//      path="/systemAdministration"
//      element={<SystemAdministration />}
//    />
//    <Route path="/tenantManagement" element={<TenantManagement />} />
//    <Route path="/staffManagement" element={<Staff />} />
//    <Route
//      path="/groupsAndPermissions"
//      element={<GroupsAndPermissions />}
//    />
//    <Route
//      path="/groupsAndPermissions/9"
//      element={<AddPermissions />}
//    />
//    <Route path="/FTBManagement" element={<FTB_Management />} />
//    <Route path="/userProfile" element={<UserProfile />} />
//    <Route path="/subscriptionPlans" element={<SubscriptionPlan />} />
//    <Route path="/subscriptionPlans/:id" element={<SpecificPlan />} />
//    <Route path="/newPlan" element={<NewSubscriptionPlan />} />
//  </>
// )}
