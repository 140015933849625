import { configureStore } from "@reduxjs/toolkit";
// import AuthSlice from "../pages/Authorization/Auth-Slice";
import loginAuth from "../slices/loginAuth";
import StaffManagementSlice from "../slices/staffManagementSlice";
import tenantMaanementSlice from "../slices/tenantMaanementSlice";
import groupPermissionSlice from "../slices/group&permissionSlice";
import visitorsSlice from "../slices/visitorsSlice";
import userProfileSlice from "../slices/userProfileSlice";
import plansSlice from "../slices/subscriptionSlice";
import newRequestSlice from "../slices/newRequestSlice";
import FTPSlice from "../slices/FTPSlice";
import dashboardSlice from "../slices/dashboardSlice";

const store = configureStore({
  reducer: {
    auth: loginAuth,
    tenant: tenantMaanementSlice,
    staff: StaffManagementSlice,
    groups: groupPermissionSlice,
    visitors: visitorsSlice,
    userProfile: userProfileSlice,
    plans: plansSlice,
    newRequest: newRequestSlice,
    ftps: FTPSlice,
    dashboard: dashboardSlice
  },
});

export default store;
