import React, { useEffect } from "react";
import LayOut from "../layout/LayOut";
import newImg from "../../data/Component 41 – 1.png";
import newImg1 from "../../data/Component 40 – 1.png";
import newImg2 from "../../data/Component 39 – 1.png";
import { useDispatch, useSelector } from "react-redux";
import { getAllTenants } from "../../slices/visitorsSlice";
import "/node_modules/flag-icons/css/flag-icons.min.css";

const Visitors = () => {
  const { allTenants } = useSelector((state) => state.visitors);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllTenants());
  }, [dispatch]);

  return (
    <LayOut>
      <div className="bg-white p-5 m-7 border border-collapse rounded-t-2xl">
        <h1 className="text-lg">Visitors Online</h1>
        <div className="border"></div>
        <div className="grid md:grid-cols-12 gap-4 mt-4">
          <div className="col-span-9">Map</div>
          <div className="col-span-3 border rounded-xl shadow-lg shadow-slate-200">
            <div className="p-5 pb-2">
              <h4 className="m-0 text-2xl font-bold">37</h4>
              <span className="text-gray-400 tracking-wide font-medium">
                Visitors online
              </span>
              <div className="border-b-2 my-2"></div>
              <ul className="list-none overflow-auto h-40">
                {allTenants?.results?.map((tenant) => (
                  <li
                    key={tenant.id}
                    className="flex justify-between border-b-2 mb-3"
                  >
                    <span className="mb-1"><span className={`fi fi-eg`}></span> {tenant.name}</span>
                    <span>{tenant.id}</span>
                  </li>
                ))}
              </ul>
              {/* <button className="tracking-wide text-center w-full">
                View all visitors stats
              </button> */}
            </div>
          </div>
        </div>
        <div className="grid sm:grid-cols-12 gap-4 mt-12">
          <div className="col-span-12 sm:col-start-1 sm:col-end-9 border rounded-xl shadow-lg shadow-slate-200">
            <div className="p-5">
              <h4 className="m-0 text-lg text-gray-700 font-medium tracking-wide">
                Traffic Statistics
              </h4>
              <div className="border-b-2 my-2"></div>
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12 lg:col-span-4 bg-gray-100 rounded p-2">
                  <div className="flex items-center">
                    <div className="">
                      <img src={newImg} alt="" className="w-12" />
                    </div>
                    <div className="ml-3">
                      <h6 className="m-0 text-2xl font-bold">247</h6>
                      <span>New visitors</span>
                    </div>
                  </div>
                </div>
                <div className="col-span-12 lg:col-span-4 bg-gray-100 rounded p-2">
                  <div className="flex items-center">
                    <div className="">
                      <img src={newImg1} alt="" className="w-12" />
                    </div>
                    <div className="ml-2">
                      <h6 className="m-0">247</h6>
                      <span>New visitors</span>
                    </div>
                  </div>
                </div>
                <div className="col-span-12 lg:col-span-4 bg-gray-100 rounded p-2">
                  <div className="flex items-center">
                    <div className="">
                      <img src={newImg2} alt="" className="w-12" />
                    </div>
                    <div className="ml-2">
                      <h6 className="m-0">247</h6>
                      <span>New visitors</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12 sm:col-start-10 sm:col-end-14 border rounded-xl shadow-lg shadow-slate-200">
            <div className="p-5">
              <h4 className="m-0 text-lg text-gray-700 font-medium tracking-wide">
                Traffic Sources
              </h4>
              <div className="border-b-2 my-2"></div>
            </div>
          </div>
        </div>
      </div>
    </LayOut>
  );
};

export default Visitors;
