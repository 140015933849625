import React, { useEffect } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import {
  links,
  linksFinancials,
  linksProfile,
  linksSystem,
} from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";
import { IoIosAddCircleOutline } from "react-icons/io";
import { CgProfile } from "react-icons/cg";
import { VscEyeClosed } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../slices/loginAuth";

const Sidebar = () => {
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);
  const {
    currentColor,
    activeMenu,
    setActiveMenu,
    screenSize,
    showSideBar,
    setShowSideBar,
    showSideBarFinancials,
    setShowSideBarFinancials,
    showSideBarSystemAdministration,
    setShowSideBarSystemAdministration,
  } = useStateContext();

  const dispatch = useDispatch();

  const handleCloseSideBar = (name) => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false);
    }

    if (name === "Log out") {
      dispatch(logout());
    }
  };

  const myLocation = useLocation().pathname;

  useEffect(() => {
    if (
      myLocation === "/financials" ||
      myLocation === "/subscriptionPlans" ||
      myLocation === `/subscriptionPlans/${id}` ||
      myLocation === "/newPlan"
    ) {
      setShowSideBarFinancials(true);
      setShowSideBar(false);
      setShowSideBarSystemAdministration(false);
    }
    if (
      myLocation === "/systemAdministration" ||
      myLocation === "/tenantManagement" ||
      myLocation === "/staffManagement" ||
      myLocation === `/groupsAndPermissions/9` ||
      myLocation === "/FTBManagement"
    ) {
      setShowSideBarFinancials(false);
      setShowSideBar(false);
      setShowSideBarSystemAdministration(true);
    }
  }, []);

  const activeLink =
    "flex items-center justify-center gap-3 pt-3 pb-2.5 text-md m-2 border-solid border-b-2 border-white text-yellow-200 hover:text-yellow-200 font-bold";
  const normalLink =
    "flex items-center justify-center gap-3 pt-3 pb-2.5 text-md text-white hover:text-yellow-200 m-2 border-solid border-b-2 border-white";

  return (
    <div
      className=" h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10 relative"
      style={{ background: "#5d337f" }}
    >
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            {/* <Link
              to="/"
              onClick={handleCloseSideBar}
              className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-white"
            >
              <SiShopware /> <span>Admin Dashboard</span>
            </Link> */}
            <span className="items-center gap-3 ml-3 mt-4 flex text-lg font-extrabold tracking-tight text-slate-100 block md:hidden">
              Hi , {user?.user?.userName}
            </span>
            <TooltipComponent content="Menu" position="BottomCenter">
              <button
                type="button"
                onClick={() => setActiveMenu(!activeMenu)}
                style={{ color: "#fff" }}
                className="text-4xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
              >
                {/* <VscEyeClosed /> */}
                <MdOutlineCancel />
              </button>
            </TooltipComponent>
          </div>
          <div className="mt-5">
            {/* **********************Links******************** */}
            {showSideBar &&
              links.map((item) => (
                <div key={item.title}>
                  {/* <p className="text-gray-300 m-3 uppercase">{item.title}</p> */}
                  {item.links.map((link) => (
                    <NavLink
                      to={`/${link.path}`}
                      key={link.name}
                      onClick={handleCloseSideBar}
                      className={({ isActive }) =>
                        `${isActive ? activeLink : normalLink} ${
                          link.name === "dashboard" && "dashBG"
                        } mb-10`
                      }
                    >
                      {link.icon}
                      <span className="capitalize">{link.name}</span>
                    </NavLink>
                  ))}
                </div>
              ))}
            {showSideBarFinancials && (
              <>
                {linksFinancials.map((item) => (
                  <div key={item.title}>
                    {/* <p className="text-gray-300 dark:text-gray-400 m-3 mt-4 uppercase">
                      {item.title}
                    </p> */}
                    {item.links.map((link) => (
                      <NavLink
                        to={`/${link.path}`}
                        key={link.name}
                        onClick={handleCloseSideBar}
                        className={({ isActive }) =>
                          `${isActive ? activeLink : normalLink} ${
                            link.name === "financials" && "dashBG"
                          } mb-10`
                        }
                      >
                        {link.icon}
                        <span className="capitalize">{link.name}</span>
                      </NavLink>
                    ))}
                  </div>
                ))}
                <NavLink to="/newPlan">
                  <IoIosAddCircleOutline
                    style={{
                      fontSize: "50px",
                      cursor: "pointer",
                      color: "#fff",
                      margin: "auto",
                    }}
                    // onClick={createfeatureHandler}
                  />
                </NavLink>
              </>
            )}
            {showSideBarSystemAdministration &&
              linksSystem.map((item) => (
                <div key={item.title}>
                  {/* <p className="text-gray-300 dark:text-gray-400 m-3 mt-4 uppercase">
                    {item.title}
                  </p> */}
                  {item.links.map((link) => (
                    <NavLink
                      to={`/${link.path}`}
                      key={link.name}
                      onClick={handleCloseSideBar}
                      className={({ isActive }) =>
                        `${isActive ? activeLink : normalLink} ${
                          link.name === "System Administration" && "dashBG"
                        } mb-10`
                      }
                    >
                      {link.icon}
                      <span className="capitalize">{link.name}</span>
                    </NavLink>
                  ))}
                </div>
              ))}
          </div>
        </>
      )}
      <div className="absolute bottom-10 w-full visible md:invisible">
        {linksProfile.map((item) => (
          <div key={item.title}>
            {item.links.map((link) => (
              <NavLink
                to={`/${link.path}`}
                key={link.name}
                onClick={() => handleCloseSideBar(link.name)}
                className={({ isActive }) =>
                  `${isActive ? activeLink : normalLink} mb-10`
                }
              >
                {link.icon}
                <span className="capitalize">{link.name}</span>
              </NavLink>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
