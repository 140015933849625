import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { getAllTenants } from "../../slices/dashboardSlice";

import { Line } from "react-chartjs-2";

const LineChart = ({ year }) => {
  const [chartData, setChartData] = useState({});
  const { allTenants } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchDate = async () => {
      const res = await dispatch(getAllTenants(year));
      const registeredDate = res.payload?.results?.map((u) =>
        new Date(u.created_at).getMonth()
      );

      let dataHash = {};
      registeredDate.forEach((age) => {
        if (dataHash[age]) {
          dataHash[age]++;
        } else {
          dataHash[age] = 1;
        }
      });

      setChartData({
        labels: Object.keys(dataHash),
        datasets: [
          {
            label: "Numbers of tenants",
            data: Object.values(dataHash),
            fill: true,
            borderWidth: 1,
            backgroundColor: "#e2f3f261",
            borderColor: ["#ccc", "red", "green", "#444", "#888", "brown"],
          },
        ],
      });
    };

    if (year) {
      fetchDate();
    }
  }, [dispatch, year]);

  return (
    <div>
      {chartData && chartData.datasets && (
        <Line
          className="lineChartWidth"
          data={chartData}
          options={{
            responsive: true,
            plugins: {
              legend: false,
            },
          }}
        ></Line>
      )}
    </div>
  );
};

export default LineChart;

/* 
plugins: {
              legend: { position: "bottom", align: "start" },
            },
*/
