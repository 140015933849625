import React from "react";
import { Navigate, Route, RouteProps } from "react-router-dom";
// type RouteProp = RouteProps & {
//     isAuthenticated: boolean;
//     redirectTo: string;
//     component : any;
//   };

// interface ProtectedRouteProps extends RouteProp {
//   isAuthenticated: boolean;
//   redirectTo: string;
// }

const ProtectedRoute= ({
  isAuthenticated,
  redirectTo,
  component: Component,
  ...rest
}) => {
  return isAuthenticated ? (
    // <Route {...rest} element ={Component} />
    Component
  ) : (
    <Navigate to={redirectTo} replace />
  );
};

export default ProtectedRoute;