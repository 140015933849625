import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import baseUrl, { authApi } from "../redux/baseUrl";

const user = localStorage.getItem("login")
  ? JSON.parse(localStorage.getItem("login"))
  : null;

export const getAllNewTenant = createAsyncThunk(
  "request/getAllNewTenant",
  async ({ page, pageSize, searchTenant }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await baseUrl.get(
        `api/v1/tenants/?new=True&page=${page}&page_size=${pageSize}&${
          searchTenant ? `name__icontains=${searchTenant}` : ""
        }`
      );

      for (let i = 0; i < response?.data?.results.length; i++) {
        let TenantID = response.data.results[i].id;
        const resUser = await baseUrl.get(
          `api/v1/users/?tenantId=${TenantID}&main=True`
        );
        if (resUser.data.count >= 1) {
          response.data.results[i]["user_id"] = resUser.data.results[0]["id"];
        }

        const resFTP = await baseUrl.get(
          `api/v1/configuration/ftp?tenant=${TenantID}`
        );

        if (resFTP.data.count >= 1) {
          response.data.results[i]["FTP_host"] = resFTP.data.results[0]["host"];
          response.data.results[i]["usernameFTP"] =
            resFTP.data.results[0]["username"];
          response.data.results[i]["passwordFTP"] =
            resFTP.data.results[0]["password"];
          response.data.results[i]["path_uploadFTP"] =
            resFTP.data.results[0]["path_upload"];
          response.data.results[i]["path_returnedFTP"] =
            resFTP.data.results[0]["path_returned"];
        }
      }

      return response.data;
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      return rejectWithValue(response.data);
    }
  }
);

// /api/v1/register/activeTenant/54

export const activeNewRequest = createAsyncThunk(
  "reqesut/activeNewRequest",
  async ({ id }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await baseUrl.put(`/api/v1/register/activeTenant/${id}`);
      return res.data;
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      return rejectWithValue(response.data);
    }
  }
);

const initialState = {
  requestsTenant: [],
  isLoading: null,
  error: null,
  reSetTenantID: null,
};

const newRequestSlice = createSlice({
  name: "newRequest",
  initialState,
  reducers: {
    toggle(state, action) {
      state.reSetTenantID = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllNewTenant.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAllNewTenant.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.requestsTenant = action.payload;
      })
      .addCase(getAllNewTenant.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const TenantIDAction = newRequestSlice.actions;

export default newRequestSlice.reducer;
