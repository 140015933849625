import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const user = localStorage.getItem("login")
  ? JSON.parse(localStorage.getItem("login"))
  : null;

export const getStaffProfile = createAsyncThunk(
  "userProfile/getStaffProfile",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.get(
        `https://py.e-mart.app/api/v1/users/staffs?user=${user.user.id}&tenantId=8`
      );

      return response.data;
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      return rejectWithValue(response.data);
    }
  }
);

const initialState = {
  staffProfile: [],
  isLoading: null,
  error: null,
};

const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStaffProfile.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getStaffProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.staffProfile = action.payload;
      })
      .addCase(getStaffProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});
export default userProfileSlice.reducer;
