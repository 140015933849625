import { Button, Checkbox, Empty, message } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  assignAllPermissions,
  getAllPermissions,
  getAllTenants,
  tenantGroupFetch,
} from "../../slices/group&permissionSlice";
import LayOut from "../layout/LayOut";
import { CheckCircleOutlined } from "@ant-design/icons";

const activeLink =
  "flex items-center justify-center pt-3 pb-2.5 text-md font-bold text-white m-1";

const AddPermissions = () => {
  let { id } = useParams();
  /* USE STATE */
  const [permissionsList, setPermissionsList] = useState([]);
  const [tablesList, setTablesList] = useState([]);
  const [allFlag, setAllFlag] = useState(false);

  const { tenants, permissions } = useSelector((state) => state.groups);

  const dispatch = useDispatch();

  const getAllGroups = async () => {
    let res = await dispatch(tenantGroupFetch());
    if (res.payload) {
      setPermissionsList(res.payload.results[0].permissions.map((p) => p.id));
    }
  };

  useEffect(() => {
    dispatch(getAllTenants());
    dispatch(getAllPermissions());
    getAllGroups();
  }, [dispatch]);

  const addPermission = (e, id) => {
    if (permissionsList.includes(id)) {
      let perList = permissionsList.filter(function (item) {
        return item !== id;
      });
      setPermissionsList(perList);
    } else {
      setPermissionsList([...permissionsList, id]);
    }
  };

  const addTablePermission = (tableId, tablesPermission) => {
    if (tablesList.includes(tableId)) {
      let tableList = tablesList.filter(function (item) {
        return item !== tableId;
      });
      setTablesList(tableList);
      let tablesId = tablesPermission.map((item) => item.id);
      let newArr = permissionsList.filter(function (el) {
        return !tablesId.includes(el);
      });
      setPermissionsList(newArr);
    } else {
      setTablesList([...tablesList, tableId]);
      let newArr = [...permissionsList];
      let tablesId = tablesPermission.map((item) => item.id);
      for (let i = 0; i < tablesId.length; i++) {
        if (!newArr.includes(tablesId[i])) {
          newArr.push(tablesId[i]);
        }
      }
      setPermissionsList(newArr);
    }
  };

  const selectAllPermissions = () => {
    if (allFlag) {
      setAllFlag(false);
      setPermissionsList([]);
      setTablesList([]);
    } else {
      setAllFlag(true);
      let prodIds = permissions.map((item) => item.id);
      setTablesList(prodIds);

      let perList = [];
      for (let i = 0; i < permissions.length; i++) {
        permissions[i].permissions.map((item) => {
          perList.push(item.id);
        });
      }
      setPermissionsList(perList);
      message.success("All Permissions Selected Successfully");
    }
  };

  const assignPermissions = async () => {
    let data = {
      group_id: id,
      permissions: permissionsList,
    };

    let response = await dispatch(assignAllPermissions(data));
    if (response.meta.requestStatus === "fulfilled") {
      message.success("Permissions Assigned Successfully");
      window.location.href = "/groupsAndPermissions";
      setPermissionsList([]);
      setTablesList([]);
    }
  };

  return (
    <LayOut>
      <div className="bg-white p-5 sm:m-7 mt-28 border border-collapse rounded-t-2xl">
        <div className="flex justify-center">
          <Button
            className="font-bold rounded tracking-wide"
            style={{
              border: "1px solid #5d337f",
              color: "#5d337f",
              fontWeight: permissionsList.length !== 0 && "bold",
              borderRadius: "3px",
            }}
            disabled={permissionsList.length === 0}
            onClick={assignPermissions}
          >
            Assign Permissions
          </Button>
        </div>
        <div className="grid grid-cols-12">
          {/* <Sidebar /> */}
          {/* <div className="col-span-12 sm:col-span-2">
            <h1 className="font-bold text-grey-700 text-center">All Tenants</h1>
            <div
              className="sm:h-screen h-60 md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10 rounded"
              style={{ border: "1px solid rgb(229 229 229)" }}
            >
              {tenants?.results?.map((tenant) => (
                <h1
                  className={activeLink}
                  style={{ background: "#5d337f" }}
                  key={tenant.id}
                >
                  {tenant.name}
                </h1>
              ))}
            </div>
          </div> */}
          {/* main Content */}
          <div className="col-span-12 sm:col-span-12">
            <div className="flex sm:justify-end justify-center m-2 sm:m-0">
              <button
                className="font-bold text-white px-3 py-2 rounded mb-2 flex items-center"
                style={{ background: "#5d337f" }}
                onClick={selectAllPermissions}
              >
                Select All Permissions
                {allFlag ? (
                  <CheckCircleOutlined
                    style={{
                      fontSize: "17px",
                      display: "inline-block",
                      marginLeft: "5px",
                    }}
                  />
                ) : null}
              </button>
            </div>
            <div className="sm:h-screen h-60 md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10 rounded ml-0 my-0 sm:ml-4 sm:my-2 border border-collapse">
              {permissions?.length > 0 ? (
                permissions.map((per) => (
                  <div key={per?.id}>
                    {per && (
                      <div
                        className="flex p-2 items-center capitalize"
                        style={{ background: "#f1f1f1" }}
                      >
                        <h5
                          style={{
                            color: "black",
                            margin: "0",
                            fontSize: "17px",
                          }}
                        >
                          {per?.model}
                        </h5>
                        <Checkbox
                          checked={tablesList.includes(per?.id)}
                          style={{ marginTop: "2px", marginLeft: "10px" }}
                          onChange={() =>
                            addTablePermission(per?.id, per?.permissions)
                          }
                        />
                      </div>
                    )}
                    {per && (
                      <div className="grid grid-cols-12">
                        {per?.permissions.length > 0 ? (
                          per?.permissions.map((item) => (
                            <div
                              className="col-span-12 sm:col-span-3 p-3"
                              key={item?.id}
                            >
                              <Checkbox
                                onChange={(e) => addPermission(e, item?.id)}
                                checked={permissionsList.includes(item?.id)}
                                className="font-semibold text-gray-400"
                              >
                                {item?.name}
                              </Checkbox>
                            </div>
                          ))
                        ) : (
                          <Empty />
                        )}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <Empty />
              )}
            </div>
          </div>
        </div>
      </div>
    </LayOut>
  );
};

export default AddPermissions;
