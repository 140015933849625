import React, { useState } from "react";
import { Button, Modal } from "antd";
import { MdDeleteForever } from "react-icons/md";
import { deleteFeature } from "../../slices/subscriptionSlice";
import { useDispatch } from "react-redux";

const DeleteFeature = ({ feature, setFlag, flag }) => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("Are you sure !!");
  const dispatch = useDispatch();

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    let res = await dispatch(deleteFeature({ id: feature.id }));

    if (res.meta.requestStatus === "fulfilled") {
      setModalText("The modal will be closed after two seconds");
      setConfirmLoading(true);
      setTimeout(() => {
        setOpen(false);
        setConfirmLoading(false);
        setFlag(!flag);
      }, 2000);
    }
  };
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <MdDeleteForever
        className="ml-2 cursor-pointer text-lg text-red-600"
        onClick={() => showModal()}
      />
      <Modal
        title="Delete Feature"
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p className="text-lg text-red-600 font-semibold">{modalText}</p>
      </Modal>
    </>
  );
};

export default DeleteFeature;
