import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import baseUrl, { authApi } from "../redux/baseUrl";

const user = localStorage.getItem("login")
  ? JSON.parse(localStorage.getItem("login"))
  : null;

export const getAllPlans = createAsyncThunk(
  "plans/getAllPlans",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await baseUrl.get(`/api/v1/plan/?main=True&order_by=value_annual`);

      return response.data;
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      return rejectWithValue(response.data);
    }
  }
);

export const getSpecificPlan = createAsyncThunk(
  "plans/getSpecificPlan",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await baseUrl.get(`/api/v1/plan/${id}/`);

      return response.data;
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      return rejectWithValue(response.data);
    }
  }
);

export const editSpecificPlan = createAsyncThunk(
  "plans/editSpecificPlan",
  async ({ data, id }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await authApi.put(`/api/v1/plan/update/${id}/`, data);
      return res.data;
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      if (response.status === 401) {
        window.location.href = "/401";
      }
      return rejectWithValue(response.data);
    }
  }
);

export const deleteFeature = createAsyncThunk(
  "plans/deleteFeature",
  async ({ id }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await authApi.delete(
        `/api/v1/plan/feature/soft-delete/${id}/`
      );

      return res.data;
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      if (response.status === 401) {
        window.location.href = "/401";
      }
      return rejectWithValue(response.data);
    }
  }
);

export const getAllFeatures = createAsyncThunk(
  "plans/getAllFeatures",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await baseUrl.get(`/api/v1/plan/feature?page_size=100`);

      return response.data;
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      return rejectWithValue(response.data);
    }
  }
);

export const createFeature = createAsyncThunk(
  "plans/createFeature",
  async (name, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await authApi.post(`api/v1/plan/feature/create/`, name);

      return res.data;
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      return rejectWithValue(response.data);
    }
  }
);

export const createPlan = createAsyncThunk(
  "plans/createPlan",
  async (planData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await authApi.post(`/api/v1/plan/create/`, planData);

      return res.data;
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      return rejectWithValue(response.data);
    }
  }
);

const initialState = {
  allPlans: [],
  allFeatures: [],
  specificPlan: {},
  isLoading: null,
  error: null,
};

const plansSlice = createSlice({
  name: "plans",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllPlans.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAllPlans.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allPlans = action.payload;
      })
      .addCase(getAllPlans.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(getSpecificPlan.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getSpecificPlan.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.specificPlan = action.payload;
      })
      .addCase(getSpecificPlan.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(getAllFeatures.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAllFeatures.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allFeatures = action.payload;
      })
      .addCase(getAllFeatures.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});
export default plansSlice.reducer;
