import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
// import authApi from "../redux/authApi";
import baseUrl, { authApiUrl, authApi } from "../redux/baseUrl";

const user = localStorage.getItem("login")
  ? JSON.parse(localStorage.getItem("login"))
  : null;

export const getAllFTPs = createAsyncThunk(
  "ftp/getAllFTPs",
  async ({ page, pageSize, searchTenant }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await baseUrl.get(
        `api/v1/configuration/ftp?order_by=-id&page=${page}&page_size=${pageSize}&${
          searchTenant ? `username__icontains=${searchTenant}` : ""
        }`
      );

      return response.data;
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      return rejectWithValue(response.data);
    }
  }
);

export const getAllTenants = createAsyncThunk(
  "ftp/getAllTenants",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await baseUrl.get(`api/v1/tenants/?tenantFtp__isnull=True&page_size=2000`);

      return response.data;
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      return rejectWithValue(response.data);
    }
  }
);

export const createFTP = createAsyncThunk(
  "ftp/createFTP",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await authApiUrl.post(
        `/api/v1/configuration/ftp/create/?tenantId=${data.tenant}`,
        data
      );

      return response.data;
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      return rejectWithValue(response?.data);
    }
  }
);

export const deleteFTP = createAsyncThunk(
  "ftp/deleteFTP",
  async ({ id, tenantID }, { rejectWithValue }) => {
    try {
      let response = await fetch(
        `https://py.e-mart.app/api/v1/configuration/ftp/delete/${id}/?tenantId=${tenantID}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      if (response.status === 403) {
        window.location.href = "/403";
      } else {
        return id;
      }
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      return rejectWithValue(response?.data);
    }
  }
);

export const editFTP = createAsyncThunk(
  "ftp/editFTP",
  async ({ data, id, tenantID }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await authApi.put(
        `/api/v1/configuration/ftp/update/${id}/?tenantId=${tenantID}`,
        data
      );

      return res.data;
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      return rejectWithValue(response.data);
    }
  }
);

const initialState = {
  allFTPs: [],
  allTenants: [],
  isLoading: null,
  error: null,
};

const FTPSlice = createSlice({
  name: "ftp",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllFTPs.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAllFTPs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allFTPs = action.payload;
      })
      .addCase(getAllFTPs.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(getAllTenants.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAllTenants.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allTenants = action.payload;
      })
      .addCase(getAllTenants.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(createFTP.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createFTP.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // state.allTenants = action.payload;
      })
      .addCase(createFTP.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});
export default FTPSlice.reducer;
