import React, { useEffect, useState } from "react";
import classes from "./login.module.css";
import emartsImg from "../../data/E-marts Bg1.png";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { loginAuth } from "../../slices/loginAuth";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const { user } = useSelector((state) => state.auth);

  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const mynavigate = useNavigate();

  // Submit Handler:
  const LoginSubmitHandler = async (e) => {
    e.preventDefault();
    const user = {
      mobile: mobile,
      password,
    };
    const res = await dispatch(loginAuth({ user: user }));

    if (res.meta.requestStatus) {
      setTimeout(() => {
        mynavigate("/401");
      }, 1000);
    }
  };

  useEffect(() => {
    if (user) {
      mynavigate("/");
    }
  }, []);

  return (
    <div className={classes.loginContainer}>
      <div className="grid grid-cols-5">
        <div className="md:col-span-2 col-span-0">
          <div className={`${classes.containerImage}`}>
            <img src={emartsImg} alt="" />
          </div>
        </div>
        <div className="col-span-5 md:col-span-3">
          <div className={classes.loginContent}>
            <h1>E-Marts</h1>
            <div className={classes.formContainer}>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Mobile</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    onChange={(e) => setMobile(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    required
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <Button
                  className={classes.btnSignIn}
                  variant="primary"
                  type="submit"
                  onClick={LoginSubmitHandler}
                >
                  Log In
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
