import { Button, Modal, Switch, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createStaff, createUser } from "../../slices/staffManagementSlice";
import DatePicker from "react-datepicker";

const CreateStaff = ({ setFlag, flag }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [nationalID, setNationalID] = useState("");
  const [startdate, setStartDate] = useState(new Date());
  const [toggle, setToggle] = useState(undefined);
  const [err, setError] = useState("");
  const [isDate, setIsDate] = useState(0);

  const dispatch = useDispatch();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const toggler = () => {
    toggle ? setToggle(false) : setToggle(true);
  };

  const handleOk = async () => {
    setError(null);
    const userData = {
      userName,
      firstName,
      lastName,
      email,
      mobile,
      password,
      is_active: toggle,
      tenant: 8,
      is_staff: true,
    };

    let res = await dispatch(createUser(userData));

    if (res.meta.requestStatus === "fulfilled") {
      const staffData = {
        nationalId: nationalID,
        user: res.payload.id,
        hiringDate: isDate
      };
      dispatch(createStaff(staffData));
    }

    if (res.payload.detail) {
      message.error(res.payload.detail);
    } else {
      setFlag(!flag);
      message.success("Staff Is Created");
      setIsModalOpen(false);
      setUserName("");
      setFirstName("");
      setLastName("");
      setEmail("");
      setMobile("");
      setPassword("");
    }
  };

  useEffect(() => {
    const day = new Date(startdate).getDate();
    const month = new Date(startdate).getMonth() + 1;
    const year = new Date(startdate).getFullYear();
    setIsDate(`${year}-${month}-${day}`);
  }, [startdate]);

  return (
    <div>
      <Button onClick={showModal}>Create Staff & User</Button>
      <Modal
        title="Create Staff & User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Cancel"
        okText="Create"
        okButtonProps={{
          disabled:
            !email ||
            !email.includes("@") ||
            !email.includes(".com") ||
            !firstName ||
            !lastName ||
            !mobile ||
            !password ||
            !userName ||
            !nationalID,
        }}
      >
        <form className="w-full max-w-lg">
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                User Name
              </label>
              <input
                className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                  userName && "border-gray-500"
                } ${
                  !userName && "border-red-500"
                } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                id="grid-first-name"
                type="text"
                placeholder="Alaa"
                onChange={(e) => setUserName(e.target.value)}
                value={userName}
              />
              {!userName && (
                <p className="text-red-500 text-xs italic">
                  Please fill out this field.
                </p>
              )}
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                First Name
              </label>
              <input
                className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                  firstName && "border-gray-500"
                } ${
                  !firstName && "border-red-500"
                } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                id="grid-first-name"
                type="text"
                placeholder="Alaa"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
              />
              {!firstName && (
                <p className="text-red-500 text-xs italic">
                  Please fill out this field.
                </p>
              )}
            </div>
            <div className="w-full md:w-1/3 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-last-name"
              >
                Last Name
              </label>
              <input
                className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                  lastName && "border-gray-500"
                } ${
                  !lastName && "border-red-500"
                } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                id="grid-first-name"
                type="text"
                placeholder="Hashem"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              {!lastName && (
                <p className="text-red-500 text-xs italic">
                  Please fill out this field.
                </p>
              )}
            </div>
          </div>
          {/* Email & Mobile */}
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                Email
              </label>
              <input
                className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                  email && "border-gray-500"
                } ${
                  (!email || !email.includes(".com") || !email.includes("@")) &&
                  "border-red-500"
                } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                id="grid-first-name"
                type="email"
                placeholder="@"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              {(!email.includes("@") || !email.includes(".com")) && (
                <p className="text-red-500 text-xs italic">
                  Please Input valid mail
                </p>
              )}
            </div>
            <div className="w-full md:w-1/3 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-last-name"
              >
                Mobile
              </label>
              <input
                className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                  mobile && "border-gray-500"
                } ${
                  !mobile && "border-red-500"
                } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                id="grid-first-name"
                type="number"
                placeholder="01+"
                onChange={(e) => setMobile(e.target.value)}
                value={mobile}
              />
              {!mobile && (
                <p className="text-red-500 text-xs italic">
                  Please fill out this field.
                </p>
              )}
            </div>
            <div className="w-full md:w-1/3 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-last-name"
              >
                Password
              </label>
              <input
                className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                  password && "border-gray-500"
                } ${
                  !password && "border-red-500"
                } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                id="grid-first-name"
                type="password"
                placeholder="****************"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
              {!password && (
                <p className="text-red-500 text-xs italic">
                  Please fill out this field.
                </p>
              )}
            </div>
          </div>
          {/* National ID & Hiring Date */}
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                National ID
              </label>
              <input
                className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                  nationalID && "border-gray-500"
                } ${
                  !nationalID && "border-red-500"
                } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                id="grid-first-name"
                type="text"
                placeholder="ID"
                onChange={(e) => setNationalID(e.target.value)}
                value={nationalID}
              />
              {!nationalID && (
                <p className="text-red-500 text-xs italic">
                  Please fill out this field.
                </p>
              )}
            </div>
            <div className="w-full md:w-1/2 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-last-name"
              >
                Hiring Date
              </label>
              <DatePicker
                className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                  startdate && "border-gray-500"
                } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                selected={startdate}
                onChange={(date) => setStartDate(date)}
              />
            </div>
          </div>
          <div className="w-full px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-last-name"
            >
              Active
            </label>
            <Switch defaultChecked={toggle} onClick={toggler} />
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default CreateStaff;
