import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "../redux/baseUrl";
import baseURL from "../redux/baseUrl";

const user = localStorage.getItem("login")
  ? JSON.parse(localStorage.getItem("login"))
  : null;

export const tenantGroupFetch = createAsyncThunk(
  "groups/tenantGroupFetch",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.get(
        `${url}/api/v1/users/groups/?name=teqneia`
      );
      return response.data;
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      return rejectWithValue(response.data);
    }
  }
);

export const getAllTenants = createAsyncThunk(
  "groups/getAllTenants",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.get(`${url}/api/v1/tenants/`);
      return response.data;
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      return rejectWithValue(response.data);
    }
  }
);

export const getAllPermissions = createAsyncThunk(
  "groups/getAllPermissions",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await axios.get(
        `${url}/api/v1/users/permissions/reshape/all`
      );
      return response.data;
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      return rejectWithValue(response.data);
    }
  }
);

export const assignAllPermissions = createAsyncThunk(
  "groups/assignAllPermissions",
  async (data = null, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await baseURL.post(
        `/api/v1/users/set-group-permission/`,
        data
      );
      return response.data;
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      return rejectWithValue(response.data);
    }
  }
);

const initialState = {
  groups: [],
  tenants: [],
  permissions: [],
  assignPermissions: {},
};

const groupSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(tenantGroupFetch.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(tenantGroupFetch.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.groups = action.payload;
      })
      .addCase(tenantGroupFetch.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(getAllTenants.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAllTenants.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.tenants = action.payload;
      })
      .addCase(getAllTenants.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(getAllPermissions.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAllPermissions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.permissions = action.payload;
      })
      .addCase(getAllPermissions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(assignAllPermissions.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(assignAllPermissions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.assignPermissions = action.payload;
      })
      .addCase(assignAllPermissions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default groupSlice.reducer;
