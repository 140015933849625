import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState } from "react";
import { Modal, Switch } from "antd";
import { AiFillEdit } from "react-icons/ai";
import "./input.css";
import { useDispatch } from "react-redux";
import { editStaff, editUser } from "../../slices/staffManagementSlice";
import DatePicker from "react-datepicker";
import toast from "react-hot-toast";

let file = "";
const EditStaff = ({ staff, setFlag, flag }) => {
  const [testCheck, setTestCheck] = useState([]);
  const [startdate, setStartDate] = useState(new Date());

  const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [nationalID, setNationalID] = useState("");
  const [toggle, setToggle] = useState(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDate, setIsDate] = useState(0);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const dispatch = useDispatch();

  const handleOk = async () => {
    const FD = new FormData();

    FD.append("nationalId", nationalID);
    FD.append("hiringDate", isDate);

    const dataUser = {
      userName,
      firstName,
      lastName,
      email,
      mobile,
      is_active: toggle,
    };

    let res = await dispatch(editStaff({ data: FD, id: staff.id }));

    let response = await dispatch(
      editUser({ data: dataUser, id: staff.user.id })
    );

    if (
      response.meta.requestStatus === "fulfilled" ||
      res.meta.requestStatus === "fulfilled"
    ) {
      toast.success("Staff edited");
      setIsModalOpen(false);
      setFlag(!flag);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setUserName(staff?.user.userName);
    setFirstName(staff?.user.firstName);
    setLastName(staff?.user.lastName);
    setEmail(staff?.user.email);
    setMobile(staff?.user.mobile);
    setAddress(staff?.address);
    // setToggle(staff?.active);
    setNationalID(staff?.nationalId);
    setToggle(staff?.user.is_active);
  }, []);

  const toggler = () => {
    toggle ? setToggle(false) : setToggle(true);
  };

  useEffect(() => {
    const day = new Date(startdate).getDate();
    const month = new Date(startdate).getMonth() + 1;
    const year = new Date(startdate).getFullYear();
    setIsDate(`${year}-${month}-${day}`);
  }, [startdate]);

  return (
    <div>
      <AiFillEdit
        className="text-green-500 cursor-pointer	border-1 border-green-600 rounded text-3xl p-1 hover:bg-green-700 hover:text-white transition-colors"
        onClick={showModal}
      />
      <Modal
        title="Edit Staff"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Cancel"
        okText="Edit"
      >
        <form className="w-full max-w-lg">
          {/* First&Last Name */}
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                User Name
              </label>
              <input
                className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                  userName && "border-gray-500"
                } ${
                  !userName && "border-red-500"
                } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                id="grid-first-name"
                type="text"
                placeholder="Alaa"
                onChange={(e) => setUserName(e.target.value)}
                value={userName}
              />
              {!userName && (
                <p className="text-red-500 text-xs italic">
                  Please fill out this field.
                </p>
              )}
            </div>
            {staff?.user.firstName && (
              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-first-name"
                >
                  First Name
                </label>
                <input
                  className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                    firstName && "border-gray-500"
                  } ${
                    !firstName && "border-red-500"
                  } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                  id="grid-first-name"
                  type="text"
                  placeholder="Alaa"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                />
                {!firstName && (
                  <p className="text-red-500 text-xs italic">
                    Please fill out this field.
                  </p>
                )}
              </div>
            )}
            {staff?.user.lastName && (
              <div className="w-full md:w-1/3 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-last-name"
                >
                  Last Name
                </label>
                <input
                  className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                    lastName && "border-gray-500"
                  } ${
                    !lastName && "border-red-500"
                  } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                  id="grid-first-name"
                  type="text"
                  placeholder="Hashem"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                {!lastName && (
                  <p className="text-red-500 text-xs italic">
                    Please fill out this field.
                  </p>
                )}
              </div>
            )}
          </div>
          {/* Email & Mobile */}
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/3 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                National ID
              </label>
              <input
                className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                  nationalID && "border-gray-500"
                } ${
                  !nationalID && "border-red-500"
                } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                id="grid-first-name"
                type="number"
                placeholder="ID"
                onChange={(e) => setNationalID(e.target.value)}
                value={nationalID}
              />
            </div>
            <div className="w-full md:w-1/3 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-last-name"
              >
                Mobile
              </label>
              <input
                className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                  mobile && "border-gray-500"
                } ${
                  !mobile && "border-red-500"
                } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                id="grid-first-name"
                type="number"
                placeholder="01+"
                onChange={(e) => setMobile(e.target.value)}
                value={mobile}
              />
              {!mobile && (
                <p className="text-red-500 text-xs italic">
                  Please fill out this field.
                </p>
              )}
            </div>
            <div className="w-full md:w-1/3 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-last-name"
              >
                Hiring Date
              </label>
              <DatePicker
                className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                  startdate && "border-gray-500"
                } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                selected={startdate}
                onChange={(date) => setStartDate(date)}
              />
            </div>
          </div>
          {/* City & Description */}
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                Email
              </label>
              <input
                className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                  email && "border-gray-500"
                } ${
                  !email && "border-red-500"
                } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                id="grid-first-name"
                type="email"
                placeholder="@"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              {!email && (
                <p className="text-red-500 text-xs italic">
                  Please fill out this field.
                </p>
              )}
            </div>
            {!staff?.user.main && (
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-last-name"
                >
                  Active
                </label>
                <Switch defaultChecked={toggle} onClick={toggler} />
              </div>
            )}
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default EditStaff;
