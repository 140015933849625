import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import baseUrl from "../redux/baseUrl";

export const getAllTenants = createAsyncThunk(
  "visitors/getAllTenants",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await baseUrl.get(`/api/v1/tenants/`);

      return response.data;
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      return rejectWithValue(response.data);
    }
  }
);

const initialState = {
  allTenants: [],
  isLoading: null,
  error: null,
};

const visitorsSlice = createSlice({
  name: "visitors",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllTenants.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAllTenants.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allTenants = action.payload;
      })
      .addCase(getAllTenants.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});
export default visitorsSlice.reducer;
