import React from "react";

const Footer = () => {
  // Create a new Date object
const currentDate = new Date();

// Get the current year
const currentYear = currentDate.getFullYear();

  return (
    <div
      style={{ background: "#f5f5f5" }}
      className="bg-slate-200 w-full text-center text-purple-800 text-base font-semibold tracking-wide py-0 pb-1 p-4 mt-6 fixed bottom-0"
    >
      Copyright &copy;{" "}
      <a
        href="https://teqneia.com/"
        className="underline font-bold"
        target={`_blank`}
      >
        TEQNEIA
      </a>{" "}
      2021 - {currentYear}
    </div>
  );
};

export default Footer;
