import React, { useEffect, useState } from "react";
import LayOut from "../layout/LayOut";
import { Button, Collapse, Pagination } from "antd";
import { AiFillDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTenantManagement,
  getAlltenantManagement,
  getTenantManagement,
} from "../../slices/tenantMaanementSlice";
import EditTenant from "./EditTenant";
const { Panel } = Collapse;

const TenantManagement = () => {
  const [flag, setFlag] = useState(false);
  const [searchTenant, setTenantSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const { allTenant, specificTenant, isLoading } = useSelector(
    (state) => state.tenant
  );
  const dispatch = useDispatch();

  const onChangeTenantID = (key) => {
    if (key) {
      dispatch(getTenantManagement(key));
    }
  };

  useEffect(() => {
    dispatch(
      getAlltenantManagement({ page: 1, pageSize: 5, searchTenant: "" })
    );
  }, [dispatch]);

  const deleteHandler = (id) => {
    dispatch(deleteTenantManagement(id));
  };

  const onPageNumberChange = (current) => {
    setPage(current);
    dispatch(getAlltenantManagement({ page: current, pageSize, searchTenant }));
  };

  useEffect(() => {
    dispatch(getAlltenantManagement({ page, pageSize, searchTenant }));
  }, [flag]);

  const onSearchTenantChange = (e) => {
    setPage(1);
    dispatch(
      getAlltenantManagement({
        page: 1,
        pageSize,
        searchTenant: searchTenant,
      })
    );
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      onSearchTenantChange();
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [searchTenant]);

  const loadingElement = (
    <div className="border border-blue-300 shadow rounded-md p-4 max-w-sm w-full mx-auto">
      <div className="animate-pulse flex space-x-4">
        <div className="rounded-full bg-slate-200 h-10 w-10"></div>
        <div className="flex-1 space-y-6 py-1">
          <div className="h-2 bg-slate-200 rounded"></div>
          <div className="space-y-3">
            <div className="grid grid-cols-3 gap-4">
              <div className="h-2 bg-slate-200 rounded col-span-2"></div>
              <div className="h-2 bg-slate-200 rounded col-span-1"></div>
            </div>
            <div className="h-2 bg-slate-200 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <LayOut>
      <div className="bg-white p-5 m-7 border border-collapse rounded-t-2xl md:m-7 mt-28">
        <form>
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only"
          >
            Search
          </label>
        </form>
        <div className="relative">
          <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <svg
              aria-hidden="true"
              className="w-5 h-5 text-gray-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </div>
          <input
            onChange={(e) => setTenantSearch(e.target.value)}
            type="search"
            id="default-search"
            className="outline-0 block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Search..."
            required=""
          />
          <button
            type="submit"
            className="text-white absolute right-2.5 bottom-2.5 bg-purple-800 hover:bg-purple-900 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
          >
            Search
          </button>
        </div>
        <div className="mt-7 m-3">
          <Collapse
            onChange={onChangeTenantID}
            accordion
            style={{ borderRadius: "6px 6px 0px 0px" }}
          >
            {allTenant?.results?.map((tenant) => (
              <Panel
                key={tenant.id}
                header={
                  <div className="flex items-center justify-between">
                    <p className="p-0 m-0 text-lg capitalize text-stone-700 font-semibold	">
                      {tenant.name}
                    </p>
                  </div>
                }
              >
                <div className="grid md:grid-cols-6 relative">
                  <div className="p-2 col-span-3 border-1 bg-slate-50 rounded-l-md text-black text-base flex items-center">
                    <ul className="m-0 p-0">
                      <li className="mb-3">
                        <span className="font-bold mr-2">Tenant_ID:</span>{" "}
                        <span className="text-slate-800 tracking-wide">
                          {specificTenant?.id}
                        </span>
                      </li>
                      <li className="mb-3">
                        <span className="font-bold mr-2">First Name:</span>{" "}
                        <span className="text-slate-800 tracking-wide">
                          {specificTenant?.first_Name
                            ? specificTenant?.first_Name
                            : "_______________"}
                        </span>
                      </li>
                      <li className="mb-3">
                        <span className="font-bold mr-2">Email:</span>{" "}
                        <span className="text-slate-800 tracking-wide">
                          {specificTenant?.email}
                        </span>
                      </li>
                      <li className="mb-3">
                        <span className="font-bold mr-2">Mobile:</span>{" "}
                        <span className="text-slate-800 tracking-wide">
                          {specificTenant?.mobile}
                        </span>
                      </li>
                      <li className="mb-3">
                        <span className="font-bold mr-2">Logo:</span>{" "}
                        {specificTenant.logo ? (
                          <img
                            className="w-80 rounded"
                            src={specificTenant.logo}
                            alt=""
                          />
                        ) : (
                          "No Logo Here"
                        )}
                      </li>
                      <li className="mb-3">
                        <span className="font-bold mr-2">Domain:</span>
                        <span className="text-slate-800 tracking-wide">
                          {specificTenant.host
                            ? specificTenant.host
                            : "No Host Exist"}
                        </span>
                      </li>
                      <li className="mb-3">
                        <span className="font-bold mr-2">Description:</span>
                        <span className="text-slate-800 tracking-wide">
                          {specificTenant.description?.en}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="p-2 col-span-3 border-1 capitalize font-medium bg-gray-50 rounded-r-md text-black text-base">
                    <ul className="m-0 p-0">
                      <li className="mb-3">
                        <span className="font-bold mr-2">Plan_ID:</span>
                        <span className="text-slate-800 tracking-wide">
                          {specificTenant.plan}
                        </span>
                      </li>
                      <li className="mb-3">
                        <span className="font-bold mr-2">Last Name:</span>
                        <span className="text-slate-800 tracking-wide">
                          {specificTenant.last_Name
                            ? specificTenant.last_Name
                            : "_______________"}
                        </span>
                      </li>
                      <li className="mb-3">
                        <span className="font-bold mr-2">Address:</span>
                        <span className="text-slate-800 tracking-wide">
                          {specificTenant.address}
                        </span>
                      </li>
                      <li className="mb-3">
                        <span className="font-bold mr-2">Created_At:</span>
                        <span className="text-slate-800 tracking-wide">
                          {new Date().getFullYear(specificTenant.created_at)}-
                          {new Date().getDate(specificTenant.created_at)}-
                          {new Date().getMonth(specificTenant.created_at)}
                        </span>
                      </li>
                      <li className="mb-3">
                        <span className="font-bold mr-2">Expiry Date:</span>
                        <span className="text-slate-800 tracking-wide">
                          {new Date().getFullYear(specificTenant.created_at) +
                            1}
                          -{new Date().getDate(specificTenant.created_at)}-
                          {new Date().getMonth(specificTenant.created_at)}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="absolute right-3 top-3">
                    <div>
                      <span className="flex">
                        <AiFillDelete
                          className="text-red-500 mr-2 cursor-pointer	border-1 border-red-600 rounded text-3xl p-1 hover:bg-red-700 hover:text-white transition-colors"
                          onClick={() => deleteHandler(specificTenant.id)}
                        />
                        <EditTenant
                          tenant={specificTenant}
                          setFlag={setFlag}
                          flag={flag}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </Panel>
            ))}
          </Collapse>
          <div className="mt-2 flex justify-between">
            <Pagination
              defaultCurrent={page}
              current={page}
              total={allTenant.count}
              onChange={onPageNumberChange}
              pageSize={pageSize}
            />
          </div>
        </div>
      </div>
    </LayOut>
  );
};

export default TenantManagement;
