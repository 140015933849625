import React, { useEffect, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

import avatar from "../data/avatar.jpg";
import { UserProfile } from ".";
import { useStateContext } from "../contexts/ContextProvider";
import { Link, NavLink } from "react-router-dom";

import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../slices/loginAuth";
// style={{textAlign: "center !important", margin: "auto", display: "block"}}

const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={() => customFunc()}
      style={{ color }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
      />
      {icon}
    </button>
  </TooltipComponent>
);

const Navbar = () => {
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const activeLinkNav =
    "flex items-center gap-5 mr-3 px-3 pt-3 pb-2.5 rounded-lg text-md text-neutral-900 bg-gray-100 hover:text-purple-800";
  const normalLinkNav =
    "flex items-center gap-5 mr-3 px-3 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray hover:text-purple-800";

  const {
    activeMenu,
    setActiveMenu,
    setScreenSize,
    screenSize,
    currentColor,
    setShowSideBar,
    setShowSideBarFinancials,
    setShowSideBarSystemAdministration,
  } = useStateContext();

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);

  const logOutHandler = async () => {
    const res = await dispatch(logout());
  };

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <Link to="/userProfile" className="block text-center">
              User Profile
            </Link>
          ),
        },
        {
          key: "2",
          label: (
            <Button
              onClick={logOutHandler}
              style={{
                textAlign: "center !important",
                margin: "auto",
                display: "block",
                width: "100%",
              }}
            >
              Log Out
            </Button>
          ),
        },
      ]}
    />
  );

  const links = [
    {
      title: "Pages",
      links: [
        {
          name: "dashboard",
          path: "dashboard",
        },
        {
          name: "financials",
          path: "subscriptionPlans",
        },
        {
          name: "system Administration",
          path: "tenantManagement",
        },
      ],
    },
  ];
  return (
    <div className="flex py-2 md:mx-6 relative">
      <div className="md:hidden">
        <NavButton
          title="menu"
          customFunc={() => setActiveMenu(() => setActiveMenu(!activeMenu))}
          color={currentColor}
          icon={<AiOutlineMenu />}
        />
      </div>
      <div className="flex items-center justify-between w-full">
        {links?.map((item) => (
          <div key={item.title} style={{ display: "flex" }}>
            {item.links.map((link) => (
              <NavLink
                to={`/${link.path}`}
                key={link.name}
                className={({ isActive }) =>
                  isActive ? activeLinkNav : normalLinkNav
                }
                onClick={() => {
                  if (link.path === "dashboard") {
                    setShowSideBar(true);
                    setShowSideBarFinancials(false);
                    setShowSideBarSystemAdministration(false);
                  }
                  if (link.path === "financials") {
                    setShowSideBar(false);
                    setShowSideBarSystemAdministration(false);
                    setShowSideBarFinancials(true);
                  }
                  if (link.path === "systemAdministration") {
                    setShowSideBar(false);
                    setShowSideBarFinancials(false);
                    setShowSideBarSystemAdministration(true);
                  }
                }}
              >
                {/* {link.icon} */}
                <span className="capitalize">{link.name}</span>
              </NavLink>
            ))}
          </div>
        ))}
        <div className="invisible md:visible">
          <Dropdown overlay={menu} className="text-center">
            <Space>
              {/* <img
                className="rounded-full w-8 h-8"
                src={avatar}
                alt="user-profile"
              /> */}
              Hi,
              <span className="text-gray-500 font-bold">
                {user?.user?.userName}
              </span>
              <DownOutlined className="mb-1" />
            </Space>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
