import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState } from "react";
import { Form, Modal, Select, Switch } from "antd";
import { AiFillEdit, AiOutlineEdit } from "react-icons/ai";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import { editFTP } from "../../slices/FTPSlice";
import toast from "react-hot-toast";

const EditFTP = ({ ftp, setFlag, flag, allTenants }) => {
  const [host, setHost] = useState("");
  const [pathUpload, setPathUpload] = useState("");
  const [pathReturned, setPathReturned] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [tenant, setTenant] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    setHost(ftp?.host);
    setPathUpload(ftp?.path_upload);
    setUserName(ftp?.username);
    setPathReturned(ftp?.path_returned);
    setPassword(ftp?.password);
    setTenant(ftp?.tenant);
  }, []);

  const handleOk = async () => {
    const dataUser = {
      host,
      path_upload: pathUpload,
      username: userName,
      path_returned: pathReturned,
      password,
      tenant,
    };

    let res = await dispatch(
      editFTP({ data: dataUser, id: ftp.id, tenantID: ftp.tenant })
    );

    if (res.meta.requestStatus === "fulfilled") {
      setIsModalOpen(false);
      setFlag(!flag);
      toast.success("FTP Deleted!");
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <AiOutlineEdit
        className="text-2xl ml-3 text-green-600 cursor-pointer"
        onClick={showModal}
      />
      <Modal
        title="Edit FTP"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Cancel"
        okText="Edit"
      >
        <form className="w-full max-w-lg">
          {/* username & host & path upload */}
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/3 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                user name
              </label>
              <input
                className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                  userName && "border-gray-500"
                } ${
                  !userName && "border-red-500"
                } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                id="grid-first-name"
                type="text"
                placeholder=""
                onChange={(e) => setUserName(e.target.value)}
                value={userName}
              />
            </div>
            <div className="w-full md:w-1/3 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-last-name"
              >
                host
              </label>
              <input
                className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                  host && "border-gray-500"
                } ${
                  !host && "border-red-500"
                } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                id="grid-first-name"
                type="text"
                placeholder="Domain"
                onChange={(e) => setHost(e.target.value)}
                value={host}
              />
            </div>
            <div className="w-full md:w-1/3 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-last-name"
              >
                Path upload
              </label>
              <input
                className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                  pathUpload && "border-gray-500"
                } ${
                  !pathUpload && "border-red-500"
                } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                id="grid-first-name"
                type="text"
                placeholder="path"
                onChange={(e) => setPathUpload(e.target.value)}
                value={pathUpload}
              />
            </div>
          </div>
          {/* path returned & password */}
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/3 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-last-name"
              >
                Path Returned
              </label>
              <input
                className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                  pathReturned && "border-gray-500"
                } ${
                  !pathReturned && "border-red-500"
                } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                id="grid-first-name"
                type="text"
                placeholder="path"
                onChange={(e) => setPathReturned(e.target.value)}
                value={pathReturned}
              />
            </div>

            <div className="w-full md:w-1/3 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-last-name"
              >
                password
              </label>
              <input
                className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                  password && "border-gray-500"
                } ${
                  !password && "border-red-500"
                } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                id="grid-first-name"
                type="password"
                placeholder="**********"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </div>
            <div className="w-full md:w-1/3 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-last-name"
              >
                Tenants
              </label>
              <Form.Item style={{ marginBottom: "0px" }}>
                <Select onChange={(e) => setTenant(e)} value={tenant}>
                  {allTenants.results?.length > 0
                    ? allTenants.results.map((tenant, index) => (
                        <Select.Option key={index} value={tenant.id}>
                          {tenant.name}
                        </Select.Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default EditFTP;
