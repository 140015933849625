import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

import { Doughnut, Pie } from "react-chartjs-2";

const PieChart = () => {
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get(
        `https://py.e-mart.app/api/v1/helper/statistics-plan-admin`
      );

      const registeredDate = data?.statistics_data?.plan?.data;

      const testLabel = registeredDate.map((e) => Object.keys(e)[0]);
      const testData = registeredDate.map((e) => Object.values(e)[0]);

      setChartData({
        labels: testLabel,
        datasets: [
          {
            label: "Subscriptions",
            data: testData,
            fill: true,
            borderWidth: 1,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(187, 247, 208, 1)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgb(118 209 149)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
          },
        ],
      });
    };

    fetchData();
  }, []);

  return (
    <div style={{ marginTop: "38px" }}>
      {chartData && chartData.datasets && (
        <Doughnut
          className="PieChartWidth"
          data={chartData}
          options={{
            responsive: true,
            plugins: {
              legend: { position: "bottom", align: "start" },
            },
          }}
        ></Doughnut>
      )}
    </div>
  );
};

export default PieChart;
