import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Bar, Doughnut, Pie } from "react-chartjs-2";
import faker from "faker";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = () => {
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get(
        `https://py.e-mart.app/api/v1/helper/statistics-annual-revenue`
      );


      const registeredDate = data?.data;

      const testLabel = registeredDate.map((e) => Object.keys(e)[0]);
      const testData = registeredDate.map((e) => Object.values(e)[0]);


      setChartData({
        labels: testLabel,
        datasets: [
          {
            label: "Annual revenue",
            data: testData,
            backgroundColor: [
              "rgba(75, 192, 192, 0.2)",
              "rgba(255, 99, 132, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: ["rgb(118 209 149)", "rgba(255, 159, 64, 1)"],
          },
        ],
      });
    };

    fetchData();
  }, []);

  return (
    <div>
      {chartData && chartData.datasets && (
        <Bar
          className="barchart"
          data={chartData}
          options={{
            responsive: true,
            plugins: {
              legend: false,
            },
          }}
        ></Bar>
      )}
    </div>
  );
};

export default BarChart;
