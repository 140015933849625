import React, { useEffect, useState } from "react";
import LayOut from "../layout/LayOut";
import { Alert, Button, Collapse, Pagination, Space } from "antd";
import { AiFillDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import EditStaff from "./EditStaff";
import {
  deleteStaffManagement,
  deleteStaffWithUser,
  getAllStaff,
} from "../../slices/staffManagementSlice";
import CreateStaff from "./CreateStaff";
import classes from "./staff.module.css";
import "./input.css";
const { Panel } = Collapse;

const Staff = () => {
  const [flag, setFlag] = useState(true);
  const [searchTenant, setTenantSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const [alarm, setAlarm] = useState(false);
  const [id, setId] = useState("");

  const { allStaff } = useSelector((state) => state.staff);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllStaff({ page, pageSize, searchTenant }));
  }, [page, pageSize, flag, dispatch, searchTenant, alarm]);

  const deleteStaff = (id, index) => {
    if (allStaff.results[index].user) {
      setId(id);
      setAlarm(true);
    } else {
      dispatch(deleteStaffManagement);
      setFlag(!flag);
    }
  };

  const deleteStaffWithUserHandler = (id) => {
    setFlag(!flag);
    dispatch(deleteStaffWithUser(id));
    setAlarm(false);
  };

  const onChange = (key) => {};

  const onShowSizeChange = (current) => {
    setPage(current);
  };

  useEffect(() => {
    dispatch(getAllStaff({ page, pageSize, searchTenant }));
  }, [flag, dispatch]);

  return (
    <LayOut>
      <div className="bg-white p-5 m-7 border border-collapse rounded-t-2xl md:m-7 mt-28">
        <form>
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only"
          >
            Search
          </label>
        </form>
        <div className="relative">
          <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <svg
              aria-hidden="true"
              className="w-5 h-5 text-gray-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </div>
          <input
            onChange={(e) => setTenantSearch(e.target.value)}
            type="search"
            id="default-search"
            className="outline-0 block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Search..."
            required=""
          />
          <button
            type="submit"
            className="text-white absolute right-2.5 bottom-2.5 bg-purple-800 hover:bg-purple-900 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
          >
            Search
          </button>
        </div>
        <div className="mt-7 m-3">
          <Collapse
            onChange={onChange}
            style={{ borderRadius: "6px 6px 0px 0px" }}
          >
            {allStaff?.results?.map((staff, index) => (
              <Panel
                key={staff.id}
                header={
                  <div className="flex items-center justify-between">
                    <p className="p-0 m-0 text-lg capitalize text-stone-700 font-semibold	">
                      {staff.user?.userName}
                    </p>
                  </div>
                }
              >
                <div className="grid md:grid-cols-6 relative">
                  <div className="p-2 col-span-3 border-1 capitalize font-medium bg-slate-50 rounded-l-md text-black text-base flex items-center">
                    <ul className="m-0 p-0">
                      <li className="mb-3">
                        <span className="font-bold mr-2">ID</span>
                        <span className="text-slate-800 tracking-wide">
                          {staff.id}
                        </span>
                      </li>
                      <li className="mb-3">
                        <span className="font-bold mr-2">FirstName:</span>{" "}
                        <span className="text-slate-800 tracking-wide">
                          {staff.user?.firstName}
                        </span>
                      </li>
                      <li className="mb-3">
                        <span className="font-bold mr-2">Mobile:</span>{" "}
                        <span className="text-slate-800 tracking-wide">
                          {staff.user?.mobile}
                        </span>
                      </li>
                      {/* <li className="mb-3">
                        <span className="font-bold mr-2">Image:</span>{" "}
                        {staff.image ? (
                          <img
                            className="w-80 rounded"
                            src={staff.image}
                            alt=""
                          />
                        ) : (
                          <span className="text-slate-800 tracking-wide">
                            No Logo Here...!!
                          </span>
                        )}
                      </li> */}
                      <li className="mb-3">
                        <span className="font-bold mr-2">national_ID:</span>{" "}
                        <span className="text-slate-800 tracking-wide">
                          {staff.nationalId}
                        </span>
                      </li>
                      <li className="mb-3">
                        <span className="font-bold mr-2">hiringDate:</span>
                        <span className="text-slate-800 tracking-wide">
                          {new Date(staff.hiringDate).toLocaleDateString()}
                        </span>
                      </li>
                      <li className="mb-3">
                        <span className="font-bold mr-2">startDate:</span>
                        <span className="text-slate-800 tracking-wide">
                          {new Date(staff.user.startDate).toLocaleDateString()}
                        </span>
                      </li>
                      <li className="mb-3">
                        <span className="font-bold mr-2">main:</span>
                        <span className="text-slate-800 tracking-wide">
                          {staff.user?.main.toString()}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="p-2 col-span-3 border-1 capitalize font-medium bg-gray-50 rounded-r-md text-black text-base">
                    <ul className="m-0 p-0">
                      <li className="mb-3">
                        <span className="font-bold mr-2">tenant:</span>
                        <span className="text-slate-800 tracking-wide">
                          {staff.user?.tenant}
                        </span>
                      </li>
                      <li className="mb-3">
                        <span className="font-bold mr-2">Last name:</span>
                        <span className="text-slate-800 tracking-wide">
                          {staff.user?.lastName}
                        </span>
                      </li>
                      <li className="mb-3">
                        <span className="font-bold mr-2">Email:</span>
                        <span className="text-slate-800 tracking-wide">
                          {staff.user?.email}
                        </span>
                      </li>
                      <li className="mb-3">
                        <span className="font-bold mr-2">Active:</span>
                        <span className="text-slate-800 tracking-wide">
                          {staff.user?.is_active.toString()}
                        </span>
                      </li>
                      {/* <li className="mb-3">
                        <span className="font-bold mr-2">active:</span>
                        <span className="text-slate-800 tracking-wide">
                          {staff.active.toString()}
                        </span>
                      </li> */}
                    </ul>
                  </div>
                  <div className="absolute right-3 top-3">
                    <div>
                      <span className="flex">
                        {!staff.user?.main && (
                          <AiFillDelete
                            className="text-red-500 mr-2 cursor-pointer	border-1 border-red-600 rounded text-3xl p-1 hover:bg-red-700 hover:text-white transition-colors"
                            onClick={() => deleteStaff(staff.user.id, index)}
                          />
                        )}
                        <EditStaff
                          staff={staff}
                          setFlag={setFlag}
                          flag={flag}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </Panel>
            ))}
          </Collapse>
          <div className="mt-2 flex justify-between">
            <Pagination
              defaultCurrent={page}
              current={page}
              total={allStaff.count}
              onChange={onShowSizeChange}
              pageSize={pageSize}
            />
            <CreateStaff setFlag={setFlag} flag={flag} />
          </div>
        </div>
      </div>
      {alarm && (
        <div className={classes.wrapper}>
          <div className="alertContainer">
            <Alert
              className="alert"
              message="Warning"
              description="Are you sure !?"
              type="info"
              action={
                <Space direction="vertical">
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => deleteStaffWithUserHandler(id)}
                  >
                    Accept
                  </Button>
                  <Button
                    size="small"
                    danger
                    type="ghost"
                    onClick={() => setAlarm(false)}
                    style={{ background: "red" }}
                  >
                    Decline
                  </Button>
                </Space>
              }
            />
          </div>
        </div>
      )}
    </LayOut>
  );
};

export default Staff;
