import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import baseUrl from "../redux/baseUrl";

export const getAllTenants = createAsyncThunk(
  "dashboard/getAllTenants",
  async (year, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await baseUrl.get(
        `/api/v1/tenants/?page_size=2000&created_at__year=${year}`
      );

      return response.data;
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      return rejectWithValue(response.data);
    }
  }
);

export const getAllPlans = createAsyncThunk(
  "dashboard/getAllPlans",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await baseUrl.get(
        `/api/v1/helper/statistics-plan-admin`
      );

      return response.data;
    } catch ({ response }) {
      if (response.status === 403) {
        window.location.href = "/403";
      }
      return rejectWithValue(response.data);
    }
  }
);

const initialState = {
  allTenants: [],
  allPlans: {},
  isLoading: null,
  error: null,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllTenants.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAllTenants.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allTenants = action.payload;
      })
      .addCase(getAllTenants.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(getAllPlans.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAllPlans.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allPlans = action.payload;
      })
      .addCase(getAllPlans.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});
export default dashboardSlice.reducer;
