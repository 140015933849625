import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { AiFillEdit } from "react-icons/ai";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./input.css";
import { useDispatch } from "react-redux";
import { editTenant } from "../../slices/tenantMaanementSlice";

let file = "";
const EditTenant = ({ tenant, setFlag, flag }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [data, setData] = useState({ en: "" });
  const [domain, setDomain] = useState("");
  const [files, setFiles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const dispatch = useDispatch();

  const handleOk = async () => {
    const FD = new FormData();

    FD.append("logo", files[0]);
    FD.append("email", email);
    FD.append("mobile", mobile);
    FD.append("name", name);
    FD.append("address", address);
    FD.append("host", domain);
    FD.append("description", JSON.stringify(data));

    let response = await dispatch(editTenant({ content: FD, id: tenant.id }));

    if (response.payload) {
      setIsModalOpen(false);
      setFlag(!flag);
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setFirstName(tenant?.first_Name);
    setLastName(tenant?.last_Name);
    setEmail(tenant?.email);
    setName(tenant?.name);
    setMobile(tenant?.mobile);
    setAddress(tenant?.address);
    setDomain(tenant?.host);
    setData({ en: tenant?.description?.en });
  }, []);

  // ClassicEditor
  const handleChange = (e, editor) => {
    setData({ en: editor.getData() });
  };

  return (
    <div>
      <AiFillEdit
        className="text-green-500 cursor-pointer border-1 border-green-600 rounded text-3xl p-1 hover:bg-green-700 hover:text-white transition-colors"
        onClick={showModal}
      />
      <Modal
        title="Edit Tenant"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Cancel"
        okText="Edit"
      >
        <form className="w-full max-w-lg">
          {/* First&Last Name */}
          <div className="flex flex-wrap -mx-3 mb-6">
            {firstName && (
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-first-name"
                >
                  First Name
                </label>
                <input
                  className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                    firstName && "border-gray-500"
                  } ${
                    !firstName && "border-red-500"
                  } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                  id="grid-first-name"
                  type="text"
                  placeholder="Alaa"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                />
                {!firstName && (
                  <p className="text-red-500 text-xs italic">
                    Please fill out this field.
                  </p>
                )}
              </div>
            )}
            {lastName && (
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-last-name"
                >
                  Last Name
                </label>
                <input
                  className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                    lastName && "border-gray-500"
                  } ${
                    !lastName && "border-red-500"
                  } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                  id="grid-first-name"
                  type="text"
                  placeholder="Hashem"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                {!lastName && (
                  <p className="text-red-500 text-xs italic">
                    Please fill out this field.
                  </p>
                )}
              </div>
            )}
          </div>
          {/* Email & Mobile */}
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                Email
              </label>
              <input
                className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                  email && "border-gray-500"
                } ${
                  !email && "border-red-500"
                } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                id="grid-first-name"
                type="email"
                placeholder="@"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              {!email && (
                <p className="text-red-500 text-xs italic">
                  Please fill out this field.
                </p>
              )}
            </div>
            <div className="w-full md:w-1/2 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-last-name"
              >
                Mobile
              </label>
              <input
                className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                  mobile && "border-gray-500"
                } ${
                  !mobile && "border-red-500"
                } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                id="grid-first-name"
                type="number"
                placeholder="01+"
                onChange={(e) => setMobile(e.target.value)}
                value={mobile}
              />
              {!mobile && (
                <p className="text-red-500 text-xs italic">
                  Please fill out this field.
                </p>
              )}
            </div>
          </div>
          {/* City & Description */}
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                Name
              </label>
              <input
                className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                  name && "border-gray-500"
                } ${
                  !name && "border-red-500"
                } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                id="grid-first-name"
                type="text"
                // placeholder="Cairo"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
              {!name && (
                <p className="text-red-500 text-xs italic">
                  Please fill out this field.
                </p>
              )}
            </div>
            <div className="w-full md:w-1/2 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-last-name"
              >
                Address
              </label>
              <input
                className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                  address && "border-gray-500"
                } ${
                  !address && "border-red-500"
                } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                id="grid-first-name"
                type="text"
                placeholder="St"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              {!address && (
                <p className="text-red-500 text-xs italic">
                  Please fill out this field.
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-100 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                Domain
              </label>
              <input
                className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                  domain && "border-gray-500"
                } ${
                  !domain && "border-red-500"
                } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                id="grid-first-name"
                type="text"
                // placeholder="Cairo"
                onChange={(e) => setDomain(e.target.value)}
                value={domain}
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-100 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                Description
              </label>
              <CKEditor
                editor={ClassicEditor}
                onChange={(e, editor) => {
                  handleChange(e, editor);
                }}
                data={data.en && data.en}
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-100 px-3 mb-6 md:mb-0">
              <div className="button-wrapper">
                <span className="label">Upload File</span>
                <input
                  type="file"
                  name="upload"
                  id="upload"
                  className="upload-box"
                  placeholder="Upload File"
                  onChange={(e) => setFiles(e.target.files)}
                  ref={(input) => {
                    file = input;
                  }}
                  multiple
                  required
                />
                {files.length > 0 && <p>{files[0].name}</p>}
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default EditTenant;
