import { Button } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { FiEdit } from "react-icons/fi";
import { MdDone, MdOutlineKeyboardBackspace } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  createFeature,
  editSpecificPlan,
  getAllFeatures,
  getSpecificPlan,
} from "../../slices/subscriptionSlice";
import LayOut from "../layout/LayOut";
import toast from "react-hot-toast";
import { IoIosAddCircleOutline } from "react-icons/io";
import { MdDeleteForever } from "react-icons/md";
import DeleteFeature from "./DeleteFeature";

const SpecificPlan = () => {
  /* Use State */
  const [flag, setFlag] = useState(false);
  const [editText, setEditText] = useState(false);
  const [name, setName] = useState("");
  const [planCode, setPlanCode] = useState("");
  const [templates, setTemplates] = useState("");
  const [storage, setStorage] = useState("");
  const [staff_account, setStaffAccount] = useState("");
  const [products, setProducts] = useState("");
  const [value_annual, setValueAnnual] = useState("");
  const [valueMonthly, setValueMonthly] = useState("");
  const [valueSetup, setValueSetup] = useState("");
  // const [valueCustomization, setValueCustomization] = useState("");
  const [promotion_setup, setPromotionSetup] = useState("");
  const [oldPrice, setOldPrice] = useState("");
  const [popular, setPopular] = useState(undefined);
  const [specificPlanIDs, setSpecificPlanID] = useState([]);
  const [feature, setFeature] = useState("");

  const { id } = useParams();
  const dispatch = useDispatch();
  const { specificPlan, isLoading, allFeatures } = useSelector(
    (state) => state.plans
  );

  const mynavigate = useNavigate();

  /* Use Effect */
  useEffect(() => {
    dispatch(getSpecificPlan(id));
    dispatch(getAllFeatures());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllFeatures());
  }, [dispatch, flag]);

  useEffect(() => {
    setName(specificPlan?.name);
    setPlanCode(specificPlan?.planCode);
    setTemplates(specificPlan?.templates);
    setStorage(specificPlan?.storage);
    setStaffAccount(specificPlan?.staff_account);
    setProducts(specificPlan?.Products);
    setValueMonthly(specificPlan?.value_monthly);
    setValueAnnual(specificPlan?.value_annual);
    setValueSetup(specificPlan?.value_setup);
    // setValueCustomization(specificPlan?.value_customization);
    setPromotionSetup(specificPlan?.promotion_setup);
    setOldPrice(specificPlan?.old_price);
    setPopular(specificPlan?.popular);
    setSpecificPlanID(specificPlan?.feature?.map((f) => f.id));
  }, [specificPlan]);

  // console.log({specificPlan})
  const loadingElement = (
    <div className="border border-blue-300 shadow rounded-md p-4 max-w-sm w-full mx-auto">
      <div className="animate-pulse flex space-x-4">
        <div className="rounded-full bg-slate-200 h-10 w-10"></div>
        <div className="flex-1 space-y-6 py-1">
          <div className="h-2 bg-slate-200 rounded"></div>
          <div className="space-y-3">
            <div className="grid grid-cols-3 gap-4">
              <div className="h-2 bg-slate-200 rounded col-span-2"></div>
              <div className="h-2 bg-slate-200 rounded col-span-1"></div>
            </div>
            <div className="h-2 bg-slate-200 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  );

  const editPlan = async (e) => {
    e.preventDefault();

    const dataUser = {
      name,
      // planCode,
      templates: templates,
      storage,
      staff_account,
      Products: products,
      value_annual: value_annual,
      value_setup: valueSetup,
      // value_customization: valueCustomization,
      promotion_setup: promotion_setup,
      old_price: oldPrice,
      popular,
      value_monthly: valueMonthly,
      main:true,
    };
    let validNumber =
      storage > 0 &&
      staff_account > 0 &&
      products > 0 &&
      value_annual > 0 &&
      valueSetup > 0 &&
      valueMonthly > 0 &&
      // valueCustomization > 0 &&
      promotion_setup > 0;

    let res = "";

    if (validNumber) {
      res = await dispatch(
        editSpecificPlan({ data: dataUser, id: specificPlan.id })
      );

      if (res.meta.requestStatus === "fulfilled") {
        toast.success("Plan is edited");
        setTimeout(() => {
          mynavigate("/subscriptionPlans");
        }, 1500);
      }
    } else {
      toast.error("values have must > 0");
    }
  };

  const editFeature = async (e) => {
    e.preventDefault();

    const dataUser = {
      feature: specificPlanIDs,
    };

    let res = await dispatch(
      editSpecificPlan({ data: dataUser, id: specificPlan.id })
    );

    if (res.payload) {
      toast.success("Feature is edited");
      setTimeout(() => {
        mynavigate("/subscriptionPlans");
      }, 1500);
    }
  };

  const addFeature = (e, id) => {
    if (specificPlanIDs.includes(id)) {
      let perList = specificPlanIDs.filter(function (item) {
        return item !== id;
      });
      setSpecificPlanID(perList);
    } else {
      setSpecificPlanID([...specificPlanIDs, id]);
    }
  };

  const createfeatureHandler = async () => {
    let res = await dispatch(createFeature({ name: feature }));

    if (res.payload) {
      toast.success("Feature is created");
      setFeature("");
      setTimeout(() => {
        mynavigate("/subscriptionPlans");
      }, 1500);
    }
  };

  return (
    <LayOut>
      {/* Return To Subscription Plans */}
      <Link style={{ color: "#5d337f" }} to="/subscriptionPlans">
        <div className="flex m-7 mt-4 mb-0 items-center cursor-pointer">
          <MdOutlineKeyboardBackspace className="mr-1 ml-2 text-xl" />
          <span className="font-semibold uppercase">Return</span>
        </div>
      </Link>
      {/* Content */}
      <div className="bg-white p-5 m-7 mt-4 border border-collapse rounded-t-2xl">
        {isLoading ? (
          loadingElement
        ) : (
          <>
            <div className="p-2 rounded flex" style={{ background: "#5d337f" }}>
              <h1 className="m-0 text-white text-center text-lg flex mx-auto items-center">
                {editText && (
                  <>
                    <input
                      type="text"
                      className="text-white  bg-transparent border-b-2 outline-none"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                    <MdDone
                      className="ml-2 cursor-pointer"
                      onClick={() => setEditText(false)}
                      style={{ opacity: `${!name ? "0" : "1"}` }}
                    />
                  </>
                )}
                {!editText && (
                  <>
                    {name}
                    <FiEdit
                      className="ml-2 cursor-pointer"
                      onClick={() => setEditText(true)}
                    />
                  </>
                )}
              </h1>
            </div>
            {/* Plan Details */}
            <div>
              {/* max-w-lg */}
              <form className="w-full mt-5 px-5">
                
                {/* STORAGE & staff_account */}
                <div className="w-full flex flex-wrap justify-evenly">
                  <div className="md:flex md:items-center  md:w-1/3">
                    <div className="md:w-1/3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        storage
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                          storage && "border-gray-500"
                        } ${
                          !storage && "border-red-500"
                        } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                        id="inline-full-name"
                        type="number"
                        value={storage}
                        onChange={(e) => setStorage(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center md:w-1/3">
                    <div className="md:w-1/3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        staff_account
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                          staff_account && "border-gray-500"
                        } ${
                          !staff_account && "border-red-500"
                        } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                        id="inline-full-name"
                        type="number"
                        value={staff_account}
                        onChange={(e) => setStaffAccount(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                {/* PRODUCTS & VALUE */}
                <div className="w-full flex flex-wrap justify-evenly">
                  <div className="md:flex md:items-center  md:w-1/3">
                    <div className="md:w-1/3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Products
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                          products && "border-gray-500"
                        } ${
                          !products && "border-red-500"
                        } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                        id="inline-full-name"
                        type="number"
                        value={products}
                        onChange={(e) => setProducts(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center md:w-1/3">
                    <div className="md:w-1/3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Value monthly
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                          valueMonthly && "border-gray-500"
                        } ${
                          !valueMonthly && "border-red-500"
                        } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                        id="inline-full-name"
                        type="number"
                        value={valueMonthly}
                        onChange={(e) => setValueMonthly(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                {/* value_annual & VALUE SETUP */}
                <div className="w-full flex flex-wrap justify-evenly">
                  <div className="md:flex md:items-center  md:w-1/3">
                    <div className="md:w-1/3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        value_annual
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                          value_annual && "border-gray-500"
                        } ${
                          !value_annual && "border-red-500"
                        } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                        id="inline-full-name"
                        type="number"
                        value={value_annual}
                        onChange={(e) => setValueAnnual(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center md:w-1/3">
                    <div className="md:w-1/3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        value Setup
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                          valueSetup && "border-gray-500"
                        } ${
                          !valueSetup && "border-red-500"
                        } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                        id="inline-full-name"
                        type="number"
                        value={valueSetup}
                        onChange={(e) => setValueSetup(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                {/* promotion_setup & CURRENCY */}
                {/* <div className="w-full flex flex-wrap justify-evenly">
                  <div className="md:flex md:items-center  md:w-1/3">
                    <div className="md:w-1/3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        promotion Setup
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                          promotion_setup && "border-gray-500"
                        } ${
                          !promotion_setup && "border-red-500"
                        } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                        id="inline-full-name"
                        type="promotion_setup"
                        value={promotion_setup}
                        onChange={(e) => setPromotionSetup(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center md:w-1/3">
                    <div className="md:w-1/3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        currency
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                          currency && "border-gray-500"
                        } ${
                          !currency && "border-red-500"
                        } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                        id="inline-full-name"
                        type="text"
                        value={currency}
                        onChange={(e) => setCurrency(e.target.value)}
                      />
                    </div>
                  </div>
                </div> */}
                {/* Old Price & promotion_setup */}
                <div className="w-full flex flex-wrap justify-evenly">
                  <div className="md:flex md:items-center md:w-1/3">
                    <div className="md:w-1/3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Old Price
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                          oldPrice && "border-gray-500"
                        } ${
                          !oldPrice && "border-red-500"
                        } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                        id="inline-full-name"
                        type="number"
                        value={oldPrice}
                        onChange={(e) => setOldPrice(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center  md:w-1/3">
                    <div className="md:w-1/3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        promotion Setup
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                          promotion_setup && "border-gray-500"
                        } ${
                          !promotion_setup && "border-red-500"
                        } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                        id="inline-full-name"
                        type="promotion_setup"
                        value={promotion_setup}
                        onChange={(e) => setPromotionSetup(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
{/* PLAN CODE & templates */}
<div className="w-full flex flex-wrap justify-evenly">
                  <div className="md:flex md:items-center md:w-1/3">
                    <div className="md:w-1/3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        templates
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                          templates && "border-gray-500"
                        } ${
                          !templates && "border-red-500"
                        } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                        id="inline-full-name"
                        type="text"
                        value={templates}
                        onChange={(e) => setTemplates(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center md:w-1/3"></div>
                   {/* <div className="md:flex md:items-center md:w-1/3">
                    <div className="md:w-1/3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Plan Code
                      </label>
                    </div>
                    <div className="md:w-3/4">
                      <input
                        className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                          planCode && "border-gray-500"
                        } ${
                          !planCode && "border-red-500"
                        } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                        id="inline-full-name"
                        type="text"
                        value={planCode}
                        onChange={(e) => setPlanCode(e.target.value)}
                      />
                    </div>
                  </div> */}
                </div>
                
                <div className="flex justify-evenly">
                  <div
                    className="flex items-center"
                    style={{ width: "78%", margin: "auto" }}
                  >
                    <input
                      className=""
                      type="checkbox"
                      checked={popular}
                      value={popular}
                      onChange={() => setPopular(!popular)}
                    />
                    <span
                      className="text-lg font-normal ml-2"
                      style={{ marginTop: "-5px" }}
                    >
                      popular
                    </span>
                  </div>
                </div>
                <div className="flex justify-end">
                  <Button
                    style={{
                      background: "#5d337f",
                      color: "#fff",
                      borderRadius: "5px",
                      fontWeight: "bold",
                      Padding: "5px 20px",
                    }}
                    onClick={editPlan}
                  >
                    Save
                  </Button>
                </div>
                <div className="border-b mt-10"></div>
              </form>
            </div>
            {/* Features */}
            <div className="feature p-5">
              <h1
                className="text-xl font-bold mb-6"
                style={{ color: "#5d337f", letterSpacing: ".2px" }}
              >
                Features
              </h1>
              <div>
                <div className="grid md:grid-cols-12 gap-4">
                  {allFeatures?.results
                    ?.filter(
                      (fea) =>
                        fea.name !== "staff_account" &&
                        fea.name !== "Number of products" &&
                        fea.name !== "Accessible design" &&
                        fea.name !== "Storage space" &&
                        fea.name !== "Integration with payment gateways"
                    )
                    .map((feature) => (
                      <div className="col-span-4 pr-2" key={feature.id}>
                        <div className="flex items-center justify-between">
                          <div>
                            <input
                              type="checkbox"
                              checked={specificPlanIDs?.includes(feature?.id)}
                              onChange={(e) => addFeature(e, feature?.id)}
                            />
                            <span
                              className="text-lg font-normal ml-2"
                              style={{ marginTop: "-4px" }}
                            >
                              {feature.name}
                            </span>
                          </div>
                          <DeleteFeature
                            feature={feature}
                            setFlag={setFlag}
                            flag={flag}
                          />
                        </div>
                      </div>
                    ))}
                </div>
                <div className="create_Feature mt-5">
                  <div className="w-full flex flex-wrap">
                    <div className="md:flex md:items-center md:w-1/3">
                      <input
                        className={`mr-2 appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white`}
                        id="inline-full-name"
                        type="text"
                        value={feature}
                        onChange={(e) => setFeature(e.target.value)}
                      />
                      <IoIosAddCircleOutline
                        style={{
                          fontSize: "30px",
                          cursor: "pointer",
                          color: "#0dc159",
                        }}
                        onClick={createfeatureHandler}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-end mt-5">
                  <Button
                    style={{
                      background: "rgb(254 240 138)",
                      color: "#5d337f",
                      borderRadius: "5px",
                      fontWeight: "bold",
                      Padding: "5px 20px",
                    }}
                    onClick={editFeature}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </LayOut>
  );
};

export default SpecificPlan;
