import { Button, Form, Pagination, Select } from "antd";
import React, { useEffect, useState } from "react";
import { MdDeleteForever } from "react-icons/md";
import { AiOutlineEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  createFTP,
  deleteFTP,
  getAllFTPs,
  getAllTenants,
} from "../../slices/FTPSlice";
import LayOut from "../layout/LayOut";
import classes from "./ftp.module.css";
import toast from "react-hot-toast";
import EditFTP from "./EditFTP";
import { TenantIDAction } from "../../slices/newRequestSlice";

const FTB_Management = () => {
  // useState
  const [host, setHost] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [pathUpload, setPathUpload] = useState("");
  const [pathReturned, setPathReturned] = useState("");
  const [tenant, setTenant] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(3);
  const [searchTenant, setTenantSearch] = useState("");
  const [isCreateFTP, setIsCreateFTP] = useState(false);
  const [flag, setFlag] = useState(true);

  // Redux
  const { allFTPs, allTenants } = useSelector((state) => state.ftps);
  const dispatch = useDispatch();

  const { reSetTenantID } = useSelector((state) => state.newRequest);

  useEffect(() => {
    if (reSetTenantID) {
      setIsCreateFTP(true);
      setTenant(reSetTenantID);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [reSetTenantID]);

  useEffect(() => {
    dispatch(getAllFTPs({ page: 1, pageSize: 3, searchTenant: "" }));
  }, [flag]);

  useEffect(() => {
    dispatch(getAllTenants());
  }, []);

  const onPageNumberChange = async (current) => {
    setPage(current);
    const res = await dispatch(
      getAllFTPs({ page: current, pageSize, searchTenant })
    );
    // dispatch(getAllFTPs({ page: current, pageSize, searchTenant }));
    if (res.meta.requestStatus === "fulfilled") {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  const onSearchTenantChange = (e) => {
    setPage(1);
    dispatch(
      getAllFTPs({
        page: 1,
        pageSize,
        searchTenant: searchTenant,
      })
    );
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      onSearchTenantChange();
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [searchTenant]);

  // Create FTB Handler
  const createFTPHandler = async () => {
    const data = {
      host,
      username: userName,
      path_upload: pathUpload,
      path_returned: pathReturned,
      password,
      tenant,
    };

    let res = await dispatch(createFTP(data));

    if (res.meta.requestStatus === "fulfilled") {
      setIsCreateFTP(false);
      setFlag(!flag);
      toast.success("FTP Created!");

      setHost("");
      setUserName("");
      setPassword("");
      setPathUpload("");
      setPathReturned("");
      setTenant("");
    }

    if (res.meta.requestStatus === "rejected") {
      if (res.payload.host) {
        toast.error(res.payload.host[0]);
      }
      if (res.payload.tenant) {
        toast.error(`Tenant: ${res.payload.tenant[0]}`);
      }
    }
  };

  const deleteFTPHnadler = async (id, tenantID) => {
    const res = await dispatch(deleteFTP({ id, tenantID }));
    if (res.payload) {
      setFlag(!flag);
      toast.success("FTP Deleted!");
    }
  };

  const isValid =
    host && userName && password && pathUpload && pathReturned && tenant;

  return (
    <LayOut>
      <div className="bg-white p-5 m-7 border border-collapse rounded-t-2xl md:m-7 mt-28">
        {/* <Button>Create FTP</Button> */}
        <div className="relative">
          <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <svg
              aria-hidden="true"
              className="w-5 h-5 text-gray-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </div>
          <input
            onChange={(e) => setTenantSearch(e.target.value)}
            type="search"
            id="default-search"
            className="outline-0 block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Search..."
            required=""
          />
          <button
            type="submit"
            className="text-white absolute right-2.5 bottom-2.5 bg-purple-800 hover:bg-purple-900 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
          >
            Search
          </button>
        </div>
        <div className="flex justify-center rounded">
          <Button
            className="mt-5"
            style={{
              border: "1px solid #5d337f",
              color: "#5d337f",
              fontWeight: "600",
              textTransform: "uppercase",
              letterSpacing: "2.2px",
            }}
            onClick={() => setIsCreateFTP(true)}
          >
            Create FTP
          </Button>
        </div>
        {/* FTP Content */}

        {/* STARTCREATE FTP */}
        <div
          className={`${isCreateFTP && classes.ftpAnimation} ${classes.ftp}`}
        >
          <div
            className="bg-slate-50 rounded-l-md border-1 text-black text-base mt-2 p-2 py-4"
            style={{ boxShadow: "0px 5px 15px #f1f1f1" }}
          >
            <form className="w-full">
              {/* *************************************** */}
              <div className="grid md:grid-cols-8 mb-3">
                <div className="col-span-4 md:flex md:items-center">
                  <div className="md:w-1/5">
                    <label
                      className="block font-bold md:text-left mb-1 md:mb-0 pr-4 text-gray-700 text-md"
                      htmlFor="inline-full-name"
                    >
                      Host
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <input
                      className={`appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white border-gray-500`}
                      id="inline-full-name"
                      type="text"
                      value={host}
                      onChange={(e) => setHost(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-span-4 md:flex md:items-center">
                  <div className="md:w-1/4">
                    <label
                      className="block font-bold md:text-left mb-1 md:mb-0 pr-4 text-gray-700 text-md"
                      htmlFor="inline-full-name"
                    >
                      Path Upload
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <input
                      className={`appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white border-gray-500`}
                      id="inline-full-name"
                      type="text"
                      value={pathUpload}
                      onChange={(e) => setPathUpload(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              {/* *************************************** */}
              <div className="grid md:grid-cols-8 mb-3">
                <div className="col-span-4 md:flex md:items-center">
                  <div className="md:w-1/5">
                    <label
                      className="block font-bold md:text-left mb-1 md:mb-0 pr-4 text-gray-700 text-md"
                      htmlFor="inline-full-name"
                    >
                      User Name
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <input
                      className={`appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white border-gray-500`}
                      id="inline-full-name"
                      type="text"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-span-4 md:flex md:items-center">
                  <div className="md:w-1/4">
                    <label
                      className="block font-bold md:text-left mb-1 md:mb-0 pr-4 text-gray-700 text-md"
                      htmlFor="inline-full-name"
                    >
                      Path Returned
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <input
                      className={`appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white border-gray-500`}
                      id="inline-full-name"
                      type="text"
                      value={pathReturned}
                      onChange={(e) => setPathReturned(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              {/* *************************************** */}
              <div className="grid md:grid-cols-8 mb-3">
                <div className="col-span-4 md:flex md:items-center">
                  <div className="md:w-1/5">
                    <label
                      className="block font-bold md:text-left mb-1 md:mb-0 pr-4 text-gray-700 text-md"
                      htmlFor="inline-full-name"
                    >
                      password
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <input
                      className={`appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white border-gray-500`}
                      id="inline-full-name"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-span-4 md:flex md:items-center">
                  <div className="md:w-1/4">
                    <label
                      className="block font-bold md:text-left mb-1 md:mb-0 pr-4 text-gray-700 text-md"
                      htmlFor="inline-full-name"
                    >
                      Tenants
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <Form.Item style={{ marginBottom: "0px" }}>
                      <Select
                        onChange={(e) => setTenant(e)}
                        value={tenant}
                        disabled={reSetTenantID}
                      >
                        {allTenants.results?.length > 0
                          ? allTenants.results.map((tenant, index) => (
                              <Select.Option key={index} value={tenant.id}>
                                {tenant.name}
                              </Select.Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
              {/* *************************************** */}
              <div className="buttons flex justify-end px-4 items-center">
                <Button
                  style={{
                    background: "rgb(254 240 138)",
                    color: "#5d337f",
                    borderRadius: "5px",
                    fontWeight: "bold",
                    Padding: "5px 20px",
                    opacity: `${!isValid ? ".5" : "1"}`,
                  }}
                  onClick={createFTPHandler}
                  disabled={!isValid}
                >
                  Create
                </Button>
                <MdDeleteForever
                  onClick={() => {
                    setIsCreateFTP(false);
                    dispatch(TenantIDAction.toggle(null));
                  }}
                  className="text-2xl ml-3 text-red-600 cursor-pointer"
                />
              </div>
            </form>
          </div>
          <div className="border-b-2 mt-10 mb-7"></div>
        </div>
        {/* END CREATE FTP */}

        {allFTPs?.results?.map((ftp) => (
          <div
            key={ftp.id}
            style={{
              opacity: `${isCreateFTP ? ".4" : "1"}`,
              cursor: `${isCreateFTP ? "not-Allowed" : ""}`,
            }}
          >
            <div className="grid md:grid-cols-10 mt-4 px-2">
              {/* <div className="col-span-3">
                <span className="font-semibold text-lg text-gray-600">
                  Tenant Name:{" "}
                  <span className="text-lg font-semibold capitalize text-black">
                    {ftp.username}
                  </span>
                </span>
              </div> */}
              <div className="col-span-3">
                <span className="font-semibold text-lg text-gray-600">
                  Tenant_ID:{" "}
                  <span className="text-lg font-semibold capitalize text-black">
                    {ftp.tenant}
                  </span>
                </span>
              </div>
            </div>
            {/* Submitform for FTP */}
            <div className="bg-slate-50 rounded-l-md border-1 text-black text-base mt-2 p-2 py-4" style={{ boxShadow: "0px 5px 15px #f1f1f1" }}> 
              <form className="w-full">
                {/* *************************************** */}
                <div className="grid md:grid-cols-8 mb-3">
                  <div className="col-span-4 md:flex md:items-center">
                    <div className="md:w-1/5">
                      <label
                        className="block font-bold md:text-left mb-1 md:mb-0 pr-4 text-gray-700 text-md"
                        htmlFor="inline-full-name"
                      >
                        Host:
                      </label>
                    </div>
                    <div className="md:w-1/2">
                      <input
                        style={{
                          border: "none",
                          background: "none",
                          // cursor: "copy",
                          fontWeight: "600",
                          color: "rgb(93 51 127)",
                        }}
                        className={`appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white border-gray-500`}
                        id="inline-full-name"
                        type="text"
                        value={ftp.host}
                        onChange={(e) => setHost(e.target.value)}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-span-4 md:flex md:items-center">
                    <div className="md:w-1/4">
                      <label
                        className="block font-bold md:text-left mb-1 md:mb-0 pr-4 text-gray-700 text-md"
                        htmlFor="inline-full-name"
                      >
                        Path Upload:
                      </label>
                    </div>
                    <div className="md:w-1/2">
                      <input
                        style={{
                          border: "none",
                          background: "none",
                          fontWeight: "600",
                          color: "rgb(93 51 127)",
                        }}
                        className={`appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white border-gray-500`}
                        id="inline-full-name"
                        type="text"
                        value={ftp.path_upload}
                        onChange={(e) => setPathUpload(e.target.value)}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                {/* *************************************** */}
                <div className="grid md:grid-cols-8 mb-3">
                  <div className="col-span-4 md:flex md:items-center">
                    <div className="md:w-1/5">
                      <label
                        className="block font-bold md:text-left mb-1 md:mb-0 pr-4 text-gray-700 text-md"
                        htmlFor="inline-full-name"
                      >
                        User Name:
                      </label>
                    </div>
                    <div className="md:w-1/2">
                      <input
                        style={{
                          border: "none",
                          background: "none",
                          fontWeight: "600",
                          color: "rgb(93 51 127)",
                        }}
                        className={`appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white border-gray-500`}
                        id="inline-full-name"
                        type="text"
                        value={ftp.username}
                        onChange={(e) => setUserName(e.target.value)}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-span-4 md:flex md:items-center">
                    <div className="md:w-1/4">
                      <label
                        className="block font-bold md:text-left mb-1 md:mb-0 pr-4 text-gray-700 text-md"
                        htmlFor="inline-full-name"
                      >
                        Path Returned:
                      </label>
                    </div>
                    <div className="md:w-1/2">
                      <input
                        style={{
                          border: "none",
                          background: "none",
                          fontWeight: "600",
                          color: "rgb(93 51 127)",
                        }}
                        className={`appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white border-gray-500`}
                        id="inline-full-name"
                        type="text"
                        value={ftp.path_returned}
                        onChange={(e) => setPathReturned(e.target.value)}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                {/* *************************************** */}
                <div className="grid md:grid-cols-8">
                  <div className="col-span-4 md:flex md:items-center">
                    <div className="md:w-1/5">
                      <label
                        className="block font-bold md:text-left mb-1 md:mb-0 pr-4 text-gray-700 text-md"
                        htmlFor="inline-full-name"
                      >
                        Password:
                      </label>
                    </div>
                    <div className="md:w-1/2">
                      <input
                        style={{
                          border: "none",
                          background: "none",
                          fontWeight: "600",
                          color: "rgb(93 51 127)",
                        }}
                        className={`appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white border-gray-500`}
                        id="inline-full-name"
                        type="password"
                        value={ftp.password}
                        onChange={(e) => setPassword(e.target.value)}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                {/* *************************************** */}
                <div className="buttons flex justify-end px-4 items-center">
                  <Button
                    style={{
                      background: "rgb(254 240 138)",
                      color: "#5d337f",
                      borderRadius: "5px",
                      fontWeight: "bold",
                      Padding: "5px 20px",
                    }}
                    disabled={
                      ftp.tenant === 33 || ftp.tenant === 5 || ftp.tenant === 34
                    }
                  >
                    Save
                  </Button>
                  {/* <AiOutlineEdit className="text-2xl ml-3 text-green-600 cursor-pointer" /> */}

                  {ftp.tenant !== 33 &&
                  ftp.tenant !== 5 &&
                  ftp.tenant !== 34 ? (
                    <>
                      <EditFTP
                        ftp={ftp}
                        setFlag={setFlag}
                        flag={flag}
                        allTenants={allTenants}
                      />
                      <MdDeleteForever
                        className="text-2xl ml-3 text-red-600 cursor-pointer"
                        onClick={() => deleteFTPHnadler(ftp.id, ftp.tenant)}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </form>
            </div>
            <div className="border-b-2 mt-10 mb-7"></div>
          </div>
        ))}
        <div className="mt-2 flex justify-between p-2">
          <Pagination
            defaultCurrent={page}
            current={page}
            total={allFTPs.count}
            onChange={onPageNumberChange}
            pageSize={pageSize}
          />
        </div>
      </div>
    </LayOut>
  );
};

export default FTB_Management;
