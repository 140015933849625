import React, { useEffect } from "react";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { Button, Modal, Switch } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getStaffProfile } from "../slices/userProfileSlice";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Input, Space } from "antd";
import LayOut from "../pages/layout/LayOut";
import { editStaff, editUser } from "../slices/staffManagementSlice";
import toast from "react-hot-toast";
import { Toaster } from "react-hot-toast";

const UserProfile = () => {
  const [flag, setFlag] = useState(false);
  const [passwordVisible, setPasswordVisible] = React.useState(false);

  const [startdate, setStartDate] = useState(new Date());

  const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [nationalID, setNationalID] = useState("");
  const [toggle, setToggle] = useState(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDate, setIsDate] = useState(0);

  const { staffProfile } = useSelector((state) => state.userProfile);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStaffProfile());
  }, []);

  useEffect(() => {
    if (staffProfile?.results?.length > 0) {
      setUserName(staffProfile?.results[0]?.user.userName);
      setFirstName(staffProfile?.results[0]?.user.firstName);
      setLastName(staffProfile?.results[0]?.user.lastName);
      setEmail(staffProfile?.results[0]?.user.email);
      setMobile(staffProfile?.results[0]?.user.mobile);
      setNationalID(staffProfile?.results[0]?.nationalId);
    }
  }, [staffProfile]);

  // Handler Function
  const editUserProfileHandler = async () => {
    const FD = new FormData();

    FD.append("nationalId", nationalID);
    FD.append("hiringDate", isDate);

    const dataUser = {
      userName,
      firstName,
      lastName,
      email,
      mobile,
      password,
    };

    let res = await dispatch(
      editStaff({ data: FD, id: staffProfile.results[0].id })
    );

    let response = await dispatch(
      editUser({ data: dataUser, id: staffProfile?.results[0]?.user.id })
    );

    if (response.payload || res.payload) {
      toast.success("User Profile Updated!");
      setIsModalOpen(false);
      setFlag(!flag);
    }
  };

  useEffect(() => {
    const day = new Date(startdate).getDate();
    const month = new Date(startdate).getMonth() + 1;
    const year = new Date(startdate).getFullYear();
    setIsDate(`${year}-${month}-${day}`);
  }, [startdate]);

  return (
    <LayOut>
      <div className="bg-white p-5 m-7 border border-collapse rounded-t-2xl">
        <h1
          className="text-center text-2xl m-0 uppercase"
          style={{ color: "#5d337f" }}
        >
          User <span>Profile</span>
        </h1>
        <div
          className="border-b-2 w-24 m-auto"
          style={{ borderColor: "#5d337f" }}
        ></div>
        <form className="w-full max-w-xlg m-auto mt-14">
          {/* First&Last Name */}
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                User Name
              </label>
              <input
                className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                  userName && "border-gray-500"
                } ${
                  !userName && "border-red-500"
                } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                id="grid-first-name"
                type="text"
                placeholder="Alaa"
                onChange={(e) => setUserName(e.target.value)}
                value={userName}
              />
              {!userName && (
                <p className="text-red-500 text-xs italic">
                  Please fill out this field.
                </p>
              )}
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                First Name
              </label>
              <input
                className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                  firstName && "border-gray-500"
                } ${
                  !firstName && "border-red-500"
                } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                id="grid-first-name"
                type="text"
                placeholder="Alaa"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
              />
              {!firstName && (
                <p className="text-red-500 text-xs italic">
                  Please fill out this field.
                </p>
              )}
            </div>
            <div className="w-full md:w-1/3 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-last-name"
              >
                Last Name
              </label>
              <input
                className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                  lastName && "border-gray-500"
                } ${
                  !lastName && "border-red-500"
                } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                id="grid-first-name"
                type="text"
                placeholder="Hashem"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              {!lastName && (
                <p className="text-red-500 text-xs italic">
                  Please fill out this field.
                </p>
              )}
            </div>
          </div>
          {/* Email & Mobile */}
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/3 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                National ID
              </label>
              <input
                className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                  nationalID && "border-gray-500"
                } ${
                  !nationalID && "border-red-500"
                } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                id="grid-first-name"
                type="number"
                placeholder="ID"
                onChange={(e) => setNationalID(e.target.value)}
                value={nationalID}
              />
            </div>
            <div className="w-full md:w-1/3 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-last-name"
              >
                Mobile
              </label>
              <input
                className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                  mobile && "border-gray-500"
                } ${
                  !mobile && "border-red-500"
                } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                id="grid-first-name"
                type="number"
                placeholder="01+"
                onChange={(e) => setMobile(e.target.value)}
                value={mobile}
              />
              {!mobile && (
                <p className="text-red-500 text-xs italic">
                  Please fill out this field.
                </p>
              )}
            </div>
            <div className="w-full md:w-1/3 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-last-name"
              >
                Hiring Date
              </label>
              <DatePicker
                className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                  startdate && "border-gray-500"
                } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                selected={startdate}
                onChange={(date) => setStartDate(date)}
              />
            </div>
          </div>
          {/* City & Description */}
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-last-name"
              >
                password
              </label>
              <Space
                className="appearance-none block w-full  text-gray-700 border rounded"
                style={{ display: "block", background: "#f3f4f6" }}
              >
                <Input.Password
                  className="block"
                  placeholder="********************"
                  style={{ background: "#f3f4f6 !important" }}
                  onChange={(e) => setPassword(e.target.value)}
                  disabled
                />
              </Space>
              {/* <Space>
              <Input.Password placeholder="input password" />
            </Space> */}
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                Email
              </label>
              <input
                className={`appearance-none block w-full bg-gray-100 text-gray-700 border ${
                  email && "border-gray-500"
                } ${
                  !email && "border-red-500"
                } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                id="grid-first-name"
                type="email"
                placeholder="@"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                disabled
                style={{ opacity: ".5", cursor: "no-drop" }}
              />
              {!email && (
                <p className="text-red-500 text-xs italic">
                  Please fill out this field.
                </p>
              )}
            </div>
            <div className="w-full px-3 mt-3 flex justify-end items-center">
              <Button
                style={{
                  background: "#5d337f",
                  color: "#fff",
                  borderRadius: "5px",
                }}
                type="submit"
                onClick={editUserProfileHandler}
                disabled={!password}
              >
                Save
              </Button>
            </div>
          </div>
        </form>
        <Toaster
          position="top-center"
          toastOptions={{
            style: {
              fontSize: "1rem",
            },
          }}
        />
      </div>
    </LayOut>
  );
};

export default UserProfile;
