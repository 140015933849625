import React, { useEffect } from "react";
import LayOut from "../layout/LayOut";
import { BsCheck2Circle } from "react-icons/bs";
import classes from "./plan.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllPlans } from "../../slices/subscriptionSlice";
import { Link } from "react-router-dom";
import GeideaLOGO from "../../data/Geidea.png";
import paypalLogo from "../../data/paypal logo.png";
import paymobLogo from "../../data/paymob logo.png";

const SubscriptionPlan = () => {
  const { allPlans } = useSelector((state) => state.plans);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllPlans());
  }, [dispatch]);

  return (
    <LayOut>
      <div className="bg-white p-5 m-7 border border-collapse rounded-t-2xl">
        <section className={classes.plans}>
          <h3>Plans</h3>
          <p>Join, Sell & Earn.</p>
          <div className="grid md:grid-cols-12 gap-4">
            {allPlans?.results?.length > 0
              ? allPlans?.results?.slice(0, 3).map((plan) => (
                  <div key={plan.id} className="col-span-4">
                    <Link to={`/subscriptionPlans/${plan.id}`}>
                      <div
                        className={`${classes.plansContent} ${classes.plansContent2}`}
                      >
                        {plan.popular === true && (
                          <div className={classes.popular}>Popular</div>
                        )}
                        <h1>{plan.name}</h1>
                        <div className={classes.priceContainer}>
                          <div className={classes.price}>
                            <p>
                              {plan.currency}{" "}
                              {new Intl.NumberFormat().format(
                                plan.value_annual
                              )}
                            </p>
                            <p>
                              {plan.currency}{" "}
                              {new Intl.NumberFormat().format(
                                plan.value_monthly
                              )}
                            </p>
                            <p>
                              {plan.currency}{" "}
                              {new Intl.NumberFormat().format(plan.value_setup)}
                            </p>
                            <p>
                              {/* {plan.currency}{" "} */}%
                              {new Intl.NumberFormat().format(
                                plan.promotion_setup
                              )}
                            </p>
                            <p>
                              {plan.currency}{" "}
                              {new Intl.NumberFormat().format(plan.total_setup)}
                            </p>
                            {/* <p className={classes.price5}>
                              {plan.currency}{" "}
                              {new Intl.NumberFormat().format(
                                +plan.value_customization + +plan.value_setup
                              )}
                            </p> */}
                          </div>
                          {/* <div className={classes.priceContent}>
                            <p>Annual</p>
                            <p>One-time</p>
                            <p>Setup fees</p>
                          </div> */}
                          <div className={classes.detailsPlans}>
                            <p>Annual</p>
                            <p>Monthly</p>
                            <p>Value setup</p>
                            <p>Promotion setup</p>
                            <p>Total setup</p>
                          </div>
                        </div>
                        <ul className="h-96 md:overflow-hidden overflow-auto md:hover:overflow-auto ">
                          <li>
                            <BsCheck2Circle className={classes.check} />
                            {plan.storage >= 1024 &&
                              plan.storage < 1000000 &&
                              plan.storage / 1024 + " GB Storage"}
                            {plan.storage < 1024 &&
                              plan.storage + " MB Storage"}
                            {plan.storage >= 1000000 && "Unlimited - Storage"}
                          </li>
                          <li>
                            <BsCheck2Circle className={classes.check} />
                            {plan.Products === 1 && plan.Products + " Products"}
                            {plan.Products > 1 &&
                              plan.Products < 1000000 &&
                              plan.Products + " Products"}
                            {plan.Products >= 1000000 && "Unlimited - Products"}
                          </li>
                          <li>
                            <BsCheck2Circle className={classes.check} />
                            {plan.templates === 1 &&
                              plan.templates + " templates"}
                            {plan.templates > 1 &&
                              plan.templates < 1000000 &&
                              plan.templates + " templates"}
                            {plan.templates >= 1000000 && "All Templates 100+"}
                          </li>
                          <li>
                            <BsCheck2Circle className={classes.check} />
                            {plan.gateways === 1 && (
                              <img
                                className={classes.paypalLogo}
                                src={paypalLogo}
                                alt=""
                              />
                            )}
                            {plan.gateways === 2 && (
                              <>
                                <img
                                  className={classes.paypalLogo}
                                  src={paypalLogo}
                                  alt=""
                                />
                                +{" "}
                                <img
                                  className={classes.GeideaLogo}
                                  src={GeideaLOGO}
                                  alt=""
                                />
                              </>
                            )}
                            {plan.gateways === 3 && (
                              <>
                                <img
                                  className={classes.paypalLogo}
                                  src={paypalLogo}
                                  alt=""
                                />
                                +{" "}
                                <img
                                  className={classes.GeideaLogo}
                                  src={GeideaLOGO}
                                  alt=""
                                />
                                +{" "}
                                <img
                                  className={classes.paymobLogo}
                                  src={paymobLogo}
                                  alt=""
                                />
                              </>
                            )}
                          </li>
                          <li>
                            <BsCheck2Circle className={classes.check} />
                            {plan.staff_account === 1 &&
                              plan.staff_account + " Account"}
                            {plan.staff_account > 1 &&
                              plan.staff_account < 1000000 &&
                              plan.staff_account + " Accounts"}
                            {plan.staff_account >= 1000000 &&
                              "Unlimited - Accounts"}
                          </li>
                          {/* *****************************FEATURES********************** */}
                          {plan.feature.map((f) => (
                            <li key={f.id}>
                              <BsCheck2Circle className={classes.check} />
                              {f.name}
                            </li>
                          ))}
                          {/* <li>
                            <BsCheck2Circle className={classes.check} />
                            Connect your own domain name
                          </li>
                          <li>
                            <BsCheck2Circle className={classes.check} />
                            Up to {plan.Products} Products
                          </li>
                          <li>
                            <BsCheck2Circle className={classes.check} />
                            {plan.Products} Categories
                          </li>
                          <li>
                            <BsCheck2Circle className={classes.check} />
                            {plan.Orders !== "*"
                              ? `Up to ${plan.Orders} Orders/month`
                              : "Unlimited orders"}
                          </li>
                          <li>
                            <BsCheck2Circle className={classes.check} />
                            {plan.Photos} UHD Photos
                          </li>
                          <li>
                            <BsCheck2Circle className={classes.check} />
                            {plan.Videos} HD Videos
                          </li> */}
                          {/* {plan.id === 4 && <li style={{ opacity: '0' }}><BsCheck2Circle className={classes.check} /></li>} */}
                        </ul>
                        {/* <button>Subscribe now</button> */}
                      </div>
                    </Link>
                  </div>
                ))
              : null}
          </div>
        </section>
      </div>
    </LayOut>
  );
};

export default SubscriptionPlan;
